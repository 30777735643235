// routes
import Router from './routes';

// theme
import ThemeConfig from './theme';

import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';

// ----------------------------------------------------------------------
// import * as firebase from 'firebase/app';
// import { firebaseConfig } from './firebaseconfig';






export default function App() {
  // firebase.initializeApp(firebaseConfig)
  return (
    <ThemeConfig>
      <ScrollToTop />
      <GlobalStyles />
      <BaseOptionChartStyle />
      <Router />
    </ThemeConfig>
  );
}
