
// // import { initializeApp } from 'firebase/app';
// // import firebase from "firebase/app";
// // import "firebase/performance";
// // import "firebase/firestore";
// // import "firebase/auth";

// export const firebaseConfig = {
//     apiKey: "AIzaSyD2PxIbUK8bjWgBHZlTXBLKMhvkt0BFkTo",
//     authDomain: "brizo-app2022.firebaseapp.com",
//     databaseURL: "https://brizo-app2022-default-rtdb.firebaseio.com",
//     projectId: "brizo-app2022",
//     storageBucket: "brizo-app2022.appspot.com",
//     messagingSenderId: "133359164269",
//     appId: "1:133359164269:web:d78a9f47e536607e3e1864",
//     measurementId: "G-WPZ0BP5XMF"
// };

// // const app = firebase.initializeApp({
   
// // });
// // if (!firebase.apps.length) {
// //     firebase.initializeApp(firebaseConfig);
// //   }
  
// //   export const perf = firebase.performance();
// //   export const auth = firebase.auth();
// //   export const db = firebase.firestore();
// //   export default firebase;

// // export default app;
// import { initializeApp } from "firebase/app";
// import "firebase/auth"
// const firebaseConfig = {
//   apiKey: "AIzaSyD2PxIbUK8bjWgBHZlTXBLKMhvkt0BFkTo",
//   authDomain: "brizo-app2022.firebaseapp.com",
//   databaseURL: "https://brizo-app2022-default-rtdb.firebaseio.com",
//   projectId: "brizo-app2022",
//   storageBucket: "brizo-app2022.appspot.com",
//   messagingSenderId: "133359164269",
//   appId: "1:133359164269:web:d78a9f47e536607e3e1864",
//   measurementId: "G-WPZ0BP5XMF"
// };

// // Initialize Firebase
// export const app = initializeApp(firebaseConfig);
// export const auth = firebase.auth(app)
// import firebase from 'firebase/compat/app';
// firebase.initializeApp({ 
//   apiKey: "AIzaSyD2PxIbUK8bjWgBHZlTXBLKMhvkt0BFkTo",
//   authDomain: "brizo-app2022.firebaseapp.com",
//   databaseURL: "https://brizo-app2022-default-rtdb.firebaseio.com",
//   projectId: "brizo-app2022",
//   storageBucket: "brizo-app2022.appspot.com",
//   messagingSenderId: "133359164269",
//   appId: "1:133359164269:web:d78a9f47e536607e3e1864",
//   measurementId: "G-WPZ0BP5XMF"
//   })
import firebase from 'firebase';


const firebaseConfig = {
  apiKey: "AIzaSyD2PxIbUK8bjWgBHZlTXBLKMhvkt0BFkTo",
  authDomain: "brizo-app2022.firebaseapp.com",
  databaseURL: "https://brizo-app2022-default-rtdb.firebaseio.com",
  projectId: "brizo-app2022",
  storageBucket: "brizo-app2022.appspot.com",
  messagingSenderId: "133359164269",
  appId: "1:133359164269:web:d78a9f47e536607e3e1864",
  measurementId: "G-WPZ0BP5XMF"
};

// Initialize Firebase
const fire = firebase.initializeApp(firebaseConfig);



export default fire;
