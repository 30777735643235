import react, { useState, Fragment, useEffect, } from "react";
import { nanoid } from "nanoid";
// import "./App.css";
// import data from "../_mocks_/user";
// import ReadOnlyRow from "./readonlyrow";
// import EditableRow from "./editablerow";
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';

import plusFill from '@iconify/icons-eva/plus-fill';
import leftarrow from '@iconify/icons-eva/arrow-circle-left-outline';
// import { Link as RouterLink, Navigate } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Modal, Box, RaisedButton,
    IconButton,
    AppBar,
    Toolbar,
} from '@mui/material';
// components
// import {  } from 'react-router'

import { Link as RouterLink, Router, useNavigate, useParams, } from 'react-router-dom';
import Carousel, { consts } from "react-elastic-carousel"
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import searchFill from '@iconify/icons-eva/arrow-back-outline';
import { alpha, styled } from '@mui/material/styles';

import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenuCategory } from '../components/_dashboard/user';


import fire from '../firebaseconfig';

import { MHidden } from '../components/@material-extend';

//



function CategoryList() {


    const TABLE_HEAD = [

        { id: 'categoryid', label: 'Id', alignRight: false },
        { id: 'CategoryName', label: 'Name', alignRight: false },
        { id: 'image', label: 'Card Image', alignRight: false },
        { id: 'flag', label: 'Flag',alignRight:false },
        // { id: 'monthlyProductId', label: 'Monthly ProductId',alignRight:false },
        // { id: 'YearlyproductId', label: 'Yearly ProductId',alignRight:false },
        { id: 'Viewpackage', label: 'Package', alignRight: false },
        { id: '' }
    ];
    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(5);


    const [contacts, setContacts] = useState([]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [newpage, setnewpage] = useState()

    const [mcatpage,setmcatpage]=useState()
    const { maincatid, catpage } = useParams();



    const navigate = useNavigate();


    // const onBackButtonEvent = (e) => {
    //     console.log("eeeee", e)
    //     e.preventDefault();

    //     window.location.replace("http://www.google.com/")

    // }


    useEffect(() => {
        // history.pushState(null, null, document.URL);
        // window.addEventListener('popstate', function () {
        //     history.pushState(null, null, document.URL);
        // });
        // window.addEventListener('popstate', onBackButtonEvent);



        if (catpage) {
            setPage(catpage)
        } else {
            setPage(0)
        }

        console.log("params", catpage)


    }, [catpage])

    useEffect(() => {
        const getpage =localStorage.getItem("mpage")
        setmcatpage(getpage)

        localStorage.setItem("mcatid", maincatid)
        if(maincatid) {
            fire.database()
            .ref('/packageList/sub-category/')
            .orderByChild('categoryid').equalTo(Number(maincatid))
            .once('value')
            .then(async (snapshot) => {
                const data = snapshot.val() ? Object.values(snapshot.val()) : []
                setContacts(data)
            })
        } else {
            fire.database()
            .ref('/packageList/sub-category/')
            .once('value')
            .then(async (snapshot) => {
                setContacts(snapshot.val() || [])
            })
        }
        

        // console.log("page",page)

    }, [maincatid])

    const handleChangePage = (event, newPage) => {
        console.log("userlist", newPage, event)
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {

        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

    };

    const handleDeleteClick = async (contactId, item) => {
        await fire.database().ref(`/packageList/sub-category/${contactId - 1}`).remove();
        const packagesSnap = await fire.database().ref(`/packageList/packageListindex/`).orderByChild('categoryid').equalTo(Number(contactId)).once('value');
        const packages = Object.values(packagesSnap.val() || []);
        packages.map(p => fire.database().ref(`/packageList/packageListindex/${p.id - 1}`).remove())
        if(maincatid) {
            fire.database()
                .ref('/packageList/sub-category/')
                .orderByChild('categoryid').equalTo(Number(maincatid))
                .once('value')
                .then(async (snapshot) => {
                    setContacts(snapshot.val() || [])
                    setPage(0);
                })
        } else {
            fire.database()
                .ref('/packageList/sub-category/')
                .once('value')
                .then(async (snapshot) => {
                    const data = Object.values(snapshot.val() || [])
                    setContacts(data || [])
                    setPage(0);
                });
        }
        
    };

    return (
        <Page title="CategoryList">
            <RootStyle>
                <ToolbarStyle>
                    <MHidden width="lgUp">
                        <IconButton onClick={() => setOpen(true)} sx={{ mr: 1, color: 'text.primary' }}>
                            <Icon icon={menu2Fill} />
                        </IconButton>
                    </MHidden>
                    <IconButton onClick={() => {
                        navigate(`/dashboard/main-category/${mcatpage}`);
                        // props.history.goBack()
                    }}>
                        <Icon icon={searchFill} width={25} height={25} />
                    </IconButton>





                </ToolbarStyle>
            </RootStyle>
            <Container>

                <Stack direction="row" justifyContent="space-between" mb={3} mt={3}>
                    <Typography variant="h4" gutterBottom>
                        Category
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        // to="/dashboard/add-product/"
                        to={`/dashboard/add-category/${page}/`}
                        startIcon={<Icon icon={plusFill} />}
                    // onClick={() => Navigate(`/dashboard/add-product/${page}`)}
                    >
                        New category
                    </Button>
                </Stack>
                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }} >
                            <Table>
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={contacts.length}
                                />
                                <TableBody >
                                    {
                                        contacts

                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => (

                                                <TableRow
                                                    hover
                                                    id={row.id}
                                                    key={index}
                                                >


                                                    <TableCell align="left" width="50px">{row?.id}</TableCell>
                                                    <TableCell align="left" style={{ textTransform: 'uppercase' }}>{row?.name}</TableCell>
                                                    <TableCell align="left" width="100px">
                                                        <div style={{

                                                            width: 100,
                                                            height: 50,
                                                            // background: 'black',
                                                        }}>
                                                            <img src={row?.image} alt="nice" width={100} height={50} />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left"  style={{ textTransform: 'capitalize' }} width="100px">
                                                        {row?.flag}
                                                    </TableCell>
                                                    {/* <TableCell align="left">
                                                        iOS: {row?.monthlyProductId} <br />
                                                        Android: {row?.androidmonthlyProductId}
                                                    </TableCell>
                                                    <TableCell align="left" >
                                                        iOS: {row?.productId} <br />
                                                        Android: {row?.androidmoYearlyProductId}
                                                    </TableCell> */}

                                                    <TableCell align="center" width="160px">
                                                        <Button
                                                            component={RouterLink}

                                                            to={`/dashboard/user/${row.categoryid}/${row.id}/0`}
                                                            variant="contained"
                                                            style={{ alignItems: "center", justifyContent: 'center', alignSelf: 'center', textAlign:'center',fontSize: 12 }}
                                                            onClick={() => { localStorage.setItem("page", page) }}
                                                        >
                                                            View Package
                                                        </Button>
                                                    </TableCell>







                                                    < TableCell align="right" width="30px">
                                                        <UserMoreMenuCategory
                                                            id={row.id}
                                                            item={contacts}
                                                            page={page}
                                                            handleDeleteClick={handleDeleteClick}
                                                            route='edit-category'
                                                        />
                                                    </TableCell>
                                                </TableRow>

                                            ))
                                    }

                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 30, 35, 40, 45, 50]}
                        component="div"
                        count={contacts.length}
                        rowsPerPage={rowsPerPage}
                        page={Math.min(contacts.length, +page)}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container >
        </Page >
    );

};

export default CategoryList;


const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}));