import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import searchFill from '@iconify/icons-eva/arrow-back-outline';
import plusFill from '@iconify/icons-eva/plus-fill';
import { alpha, styled } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useParams, withRouter } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Container,
  Typography,
  Button,
  Box,
  AppBar,
  Toolbar,
  inputAdornmentClasses
} from '@mui/material';

import {
  ProductSort,
  ProductList,
  ProductCartWidget,
  ProductFilterSidebar
} from '../components/_dashboard/products';

import fire from '../firebaseconfig';
import Page from '../components/Page';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
import { MHidden } from '../components/@material-extend';

export default function AddMainCategory(props) {
  const navigate = useNavigate();
  const [colors, setcolor] = useState('#000000');
  const [name, setname] = useState();
  const [flag, setflag] = useState();
  const [monthid, setmonthid] = useState();
  const [productid, setproductid] = useState();
  const [androidmonthlyProductId, setandroidmonthlyProductId] = useState();
  const [androidmoYearlyProductId, setandroidmoYearlyProductId] = useState();
  const [category, setcategory] = useState([]);
  const { id } = useParams();
  const { catpage } = useParams();
  const [image, setImage] = useState();
  const [url, setUrl] = useState();
  const [editimage, setimageedit] = useState();
  const [isSubmitting, setsubmitting] = useState(false);

  const LoginSchema = Yup.object().shape({
    name: Yup.string().trim().required('Name is required')
    // flag: Yup.string().trim().required('Flag is required'),
    // monthid: Yup.string().trim().required('iOS Monthly ProductId is required'),
    // productid: Yup.string().trim().required('iOS Yearly ProductId is required'),
    // androidmonthlyProductId: Yup.string().trim().required('Android Monthly ProductId is required'),
    // androidmoYearlyProductId: Yup.string().trim().required('Android Yearly ProductId is required'),
  });

  useEffect(() => {
    // console.log("paage", page)
    fire
      .database()
      .ref('/packageList/main-category/')
      .once('value')
      .then(async (snapshot) => {
        setcategory(snapshot.val() || []);
      });
    if (id) {
      console.log('id', id);
      fire
        .database()
        .ref(`/packageList/main-category/${id}`)
        .once('value')
        .then((snapshot) => {
          setFieldValue('name', snapshot.val()?.name);
          // setFieldValue('flag', snapshot.val()?.flag);
          // setFieldValue('monthid', snapshot.val()?.monthlyProductId);
          // setFieldValue('productid', snapshot.val()?.productId);
          // setFieldValue('androidmonthlyProductId', snapshot.val()?.androidmonthlyProductId);
          // setFieldValue('androidmoYearlyProductId', snapshot.val()?.androidmoYearlyProductId);
          setimageedit(snapshot.val()?.image);
          // setcolor(snapshot.val()?.colorcode)
        });
    }
  }, []);

  const handleImageChange = (e) => {
    console.log('eeeee', e, e.target.files[0]);
    if (e.target.files[0]) {
      console.log('ifeeeee', e, e.target.files[0]);
      setImage(e.target.files[0]);
    }
  };

  const uploadaimage = () =>
    new Promise((resolve, reject) => {
      try {
        console.log('image.nameimage.nameimage.nameimage.name', image.name);
        if (image) {
          const uploadTask = fire.storage().ref(`/main-category/${image.name}`).put(image);
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              console.log('xyz', snapshot);
            },
            (error) => {
              reject(error);
            },

            () => {
              fire
                .storage()
                .ref('/main-category/')
                .child(image.name)
                .getDownloadURL()
                .then(async (aurl) => {
                  console.log('console', aurl);
                  await setUrl(aurl);
                  dataaddimagefire(aurl);
                  resolve(true);
                });
            }
          );
        }
      } catch (err) {
        reject(err);
      }
    });

  const dataaddimage = (e) =>
    new Promise((resolve, reject) => {
      // console.log("url********************",url)
      if (id) {
        console.log(e.name);
        fire
          .database()
          .ref(`/packageList/main-category/${id}/`)
          .update({
            name: e.name
            // flag: e.flag,
            // productId: e.productid,
            // monthlyProductId: e.monthid,
            // androidmonthlyProductId: e.androidmonthlyProductId,
            // androidmoYearlyProductId: e.androidmoYearlyProductId
            //  image:url
          })
          .then(() => {
            console.log('updateed');
            navigate(`/dashboard/main-category/${catpage}`);
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        fire
          .database()
          .ref(`/packageList/main-category/${category.length}`)
          .update({
            id: category.length + 1,
            name: e.name
            // flag: e.flag,
            // productId: e.productid,
            // monthlyProductId: e.monthid,
            // androidmonthlyProductId: e.androidmonthlyProductId,
            // androidmoYearlyProductId: e.androidmoYearlyProductId
          })
          .then(() => {
            console.log('updateed');
            navigate(`/dashboard/main-category/${catpage}`);
          });
      }
    });

  const dataaddimagefire = (aurl) =>
    new Promise((resolve, reject) => {
      // console.log("url********************",url)
      if (id) {
        fire
          .database()
          .ref(`/packageList/main-category/${id}/`)
          .update({
            image: aurl
          })
          .then(() => {
            console.log('updateed');
            navigate(`/dashboard/main-category/${catpage}`);
            resolve(true);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        fire
          .database()
          .ref(`/packageList/main-category/${category.length}`)
          .update({
            image: aurl
          })
          .then(() => {
            console.log('updateed');
            navigate(`/dashboard/main-category/${catpage}`);
          });
      }
    });
  const formik = useFormik({
    initialValues: {
      name
      // flag,
      // monthid,
      // productid,
      // androidmonthlyProductId,
      // androidmoYearlyProductId
    },
    validationSchema: LoginSchema,
    onSubmit: async (e) => {
      console.log(image, e);
      if (id) {
        if (image) {
          setsubmitting(true);
          await uploadaimage();
          await dataaddimage(e);
          navigate(`/dashboard/main-category/${catpage}`);
          setsubmitting(false);
        } else {
          setsubmitting(true);
          await dataaddimage(e);
          navigate(`/dashboard/main-category/${catpage}`);
          setsubmitting(false);
        }
      }
      if (!id) {
        if (!image) {
          alert('Category Card image is required');
          setsubmitting(false);
        } else {
          setsubmitting(true);
          await uploadaimage();
          await dataaddimage(e);
          navigate(`/dashboard/main-category/${catpage}`);
          setsubmitting(false);
        }
      }
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;
  return (
    <Page title="AddMainCategory">
      <RootStyle>
        <ToolbarStyle>
          <MHidden width="lgUp">
            <IconButton
            // onClick={() => setOpen(true)} sx={{ mr: 1, color: 'text.primary' }}
            >
              <Icon icon={menu2Fill} />
            </IconButton>
          </MHidden>
          <IconButton
            onClick={() => {
              navigate(`/dashboard/main-category/${catpage}`);
              // props.history.goBack()
            }}
          >
            <Icon icon={searchFill} width={25} height={25} />
          </IconButton>
        </ToolbarStyle>
      </RootStyle>
      {/* <DashboardSidebar isOpenSidebar={() => setOpen(true)} onCloseSidebar={() => setOpen(false)} /> */}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" sx={{ marginTop: 5 }}>
            {id ? 'Edit Main Category' : 'Add Main Category'}
            {/* Add Category */}
          </Typography>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                // autoComplete="username"
                data-shrink="true"
                type="text"
                InputLabelProps={{ shrink: true }}
                // required
                value={name}
                // defaultValue={name}
                onChange={(e) => setFieldValue('name', e.target.value)}
                label="Enter Main Category Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />
              {/* <TextField
                fullWidth
                select
                // autoComplete="username"
                data-shrink="true"
                // type="text"
                InputLabelProps={{ shrink: true }}
                // required
                value={flag}
                // defaultValue={name}
                onChange={(e) => setFieldValue('flag', e.target.value)}
                label="Premium/Free"
                {...getFieldProps('flag')}
                error={Boolean(touched.flag && errors.flag)}
                helperText={touched.flag && errors.flag}
                SelectProps={{
                  native: true,
                }}      
              >
                <option value="">Select Flag</option>
                <option value="Premium">Premium</option>
                <option value="Free">Free</option>
              </TextField> */}
              {/* <TextField
                fullWidth
                // autoComplete="username"
                data-shrink="true"
                type="text"
                InputLabelProps={{ shrink: true }}
                // required
                value={monthid}
                // defaultValue={name}
                onChange={(e) => setFieldValue('monthid', e.target.value)}
                label="iOS Monthly ProductId"
                {...getFieldProps('monthid')}
                error={Boolean(touched.monthid && errors.monthid)}
                helperText={touched.monthid && errors.monthid}
              />
              <TextField
                fullWidth
                // autoComplete="username"
                data-shrink="true"
                type="text"
                InputLabelProps={{ shrink: true }}
                // required
                value={productid}
                // defaultValue={name}
                onChange={(e) => setFieldValue('productid', e.target.value)}
                label="iOS Yearly ProductId"
                {...getFieldProps('productid')}
                error={Boolean(touched.productid && errors.productid)}
                helperText={touched.productid && errors.productid}
              />
              <TextField
                fullWidth
                // autoComplete="username"
                data-shrink="true"
                type="text"
                InputLabelProps={{ shrink: true }}
                // required
                value={androidmonthlyProductId}
                // defaultValue={name}
                onChange={(e) => setFieldValue('androidmonthlyProductId', e.target.value)}
                label="Android Monthly ProductId"
                {...getFieldProps('androidmonthlyProductId')}
                error={Boolean(touched.androidmonthlyProductId && errors.androidmonthlyProductId)}
                helperText={touched.androidmonthlyProductId && errors.androidmonthlyProductId}
              />
              <TextField
                fullWidth
                // autoComplete="username"
                data-shrink="true"
                type="text"
                InputLabelProps={{ shrink: true }}
                // required
                value={androidmoYearlyProductId}
                // defaultValue={name}
                onChange={(e) => setFieldValue('androidmoYearlyProductId', e.target.value)}
                label="Android Yearly ProductId"
                {...getFieldProps('androidmoYearlyProductId')}
                error={Boolean(touched.androidmoYearlyProductId && errors.androidmoYearlyProductId)}
                helperText={touched.androidmoYearlyProductId && errors.androidmoYearlyProductId}
              /> */}
              <h5>Main Category Card Image</h5>
              {/* <text>Card Color</text>
              <Stack direction="row" alignItems="center" spacing={5}>

                <input type='image' value={colors} onChange={(e) => setcolor(e.target.value)} />

                <text>{colors}</text>
              </Stack> */}

              <input
                type="file"
                onChange={(e) => {
                  handleImageChange(e);
                }}
              />
              {id ? (
                <img
                  alt="nice"
                  src={editimage}
                  width={100}
                  height={100}
                  style={{ marginTop: 10 }}
                />
              ) : null}
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              // color='#2699FB'
              loading={isSubmitting}
              style={{ marginTop: 40 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));
