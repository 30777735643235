import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// import firebase from 'firebase/compat/auth';
// import auth from 'firebase/auth';
// import 'firebase/compat/auth';
// import firebase from "firebase/app";
// import "firebase/auth";
// import { auths } from 'src/firebaseconfig';

// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import fire from '../../../firebaseconfig';
// import { auth } from 'src/firebaseconfig';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [emails,setemail]=useState('');
  const [passwords,setpassword]=useState('');
  const [isSubmitting,setsubmitting]=useState(false)

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: (e) => {

      // alert(`emailpassword******* ${emails} ********`)
// console.log(`emailpassword******* ${e.email} ${e.password}`)
        try {
          // console.log(e.email,e.password)
        //   // await signInWithEmailAndPassword(auth, email, password);

        fire.auth()
        .signInWithEmailAndPassword(e.email,e.password).then(async(res)=>  {
          setsubmitting(true)
        const jwtToken = fire.auth().onAuthStateChanged((user)=> {
            if (user) {
              user.getIdToken().then((idToken)=> { 
                localStorage.setItem("token",idToken) 
                  // console.log(idToken); 
                  // return idToken;
              });
            }
          });
        
          // console.log(res.user.uid)
          await fire.database()
          .ref(`/adminlogin/${res.user.uid}`)
          .once('value')
          .then(snapshot => {
            // console.log('&&&&&&&&&&&&***************************', snapshot.val().isadmin);
            if(snapshot.val()?.isadmin === true){
              navigate('/dashboard/main-category/0')
              setsubmitting(false)
            }
            else{
              setsubmitting(false)
              alert("user not found")
            }
           })
            // setfromfill(snapshot.val()?.formFill)
            // if (snapshot.val()?.formFill != false && _userInfo?.uid == null) {

            //   props.navigation.navigate('HomeScreen');

            // } else {
            //   props.navigation.navigate('ProfileScreen');
            // }
          }) .catch((err) => {
              switch (err.code) {
                case 'auth/invalid-email':
                  alert("invalid email");
                  break;
                case "auth/user-not-found":
                  alert("User not Found");
                  break;
                case "auth/wrong-password":
                  alert("Invalid Email or Password");
                  break;
                default:
                  break;
              }
            });
  
         
        // auths(e.email,e.password)
        } catch (err) {
          console.error(err);
          alert(err.message);
        }
        
      // navigate('/dashboard', { replace: true });
    }
  });

  const { errors, touched, values,handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
//   const handleSubmit=(e)=>{
    
// console.log("xyzzzz",e)
//   }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            value={emails}
            onChange={(e)=>setemail(e.target.value)}
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            value={passwords}
            onChange={(e)=>setpassword(e.target.value)}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          {/* <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link> */}
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          // color='#2699FB'
          loading={isSubmitting}
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
