// material
import { Box, Grid, Container, Typography } from '@mui/material';
import { useEffect,useState } from 'react';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';
import fire from '../firebaseconfig';
// ----------------------------------------------------------------------

export default function DashboardApp(props) {
  
  const [getusers,setuser]=useState()

  useEffect(() => {
    //  fire.database()
    //   .ref('/userInfo/')
    //   .once('value')
    //   .then(async (snapshot) => {
    //     setuser(snapshot.val().length)
    //     console.log("xyz", snapshot.val())

    //   }).catch(e => console.log("error", e));
   
      fire
        .database()
        .ref('/userInfo/')
        .once('value')
          .then(async (snapshot) => {
          const data = [];
    
          snapshot.forEach((childSnap) =>{
            data.push({
              key: childSnap.key,
              data: childSnap.val()
            });
          });
    console.log("datatat****",data.length)
         setuser(data.length)
        });
    
    
  }, [])
  return (
    <Page title="Dashboard | Brizo">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklySales  getuser={getusers} />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={3}>
            <AppNewUsers />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppItemOrders />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppBugReports />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
}
