import { useEffect,useState } from 'react';
import { Link, Navigate, useRoutes ,Route} from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import AddProduct from './pages/AddProduct';
import EditProduct from './pages/EditProduct';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import PackageList from './pages/PackageList'
import NotFound from './pages/Page404';
import CategoryList from './pages/Category';
import AddCategory from './pages/AddCategory';
import MainCategoryList from './pages/MainCategory';
import AddMainCategory from './pages/AddMainCategory';


// ----------------------------------------------------------------------

export default function Router() {
  const [token,settoken]=useState()

  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
      { element: <Navigate to="/dashboard/category/0"  replace/> },
      { path: 'app', element: <DashboardApp /> },
      { path: 'category/:catpage', element:<CategoryList />},
      { path: 'category/:catpage/:maincatid', element:<CategoryList />},
      { path: 'main-category/:catpage', element:<MainCategoryList />},
      { path: 'add-category/:catpage', element: <AddCategory/>},
      { path: 'edit-category/:id/:catpage', element: <AddCategory /> },
      { path: 'add-main-category/:catpage', element: <AddMainCategory/>},
      { path: 'edit-main-category/:id/:catpage', element: <AddMainCategory /> },
      { path: 'user/:mcatid/:catid/:page', element: <PackageList /> },
      { path: 'add-product/:page', element: <AddProduct/>},
      { path: 'edit-product/:id/:page', element: <AddProduct /> },
      { path: 'blog', element: <Blog /> }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
      
        {element:<Navigate to="/login" replace/>},
        { path: 'login', element: <Login /> },
      
        {
          path: '/dashboard',
          element: <DashboardLayout />,
          children: [
            { element: <Navigate to="/dashboard/category/0"  replace/> },
            { path: 'category/:catpage', element:<CategoryList />},
            { path: 'category/:catpage/:maincatid', element:<CategoryList />},
            { path: 'main-category/:catpage', element:<MainCategoryList />},
            { path: 'add-category/:catpage', element: <AddCategory/>},
            { path: 'edit-category/:id/:catpage', element: <AddCategory /> },
            { path: 'add-main-category/:catpage', element: <AddMainCategory/>},
            { path: 'edit-main-category/:id/:catpage', element: <AddMainCategory /> },
            { path: 'user/:mcatid/:catid/:page', element: <PackageList /> },
            // { path: 'products', element: <Products /> },
            { path: 'add-product/:page', element: <AddProduct/>},
            { path: 'edit-product/:id/:page', element: <AddProduct /> },
            { path: 'blog', element: <Blog /> }
          ]
        },
       
      
        // { path: '/', element: <Login/> },
      ]
      
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
