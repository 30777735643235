import react, { useState, Fragment, useEffect, } from "react";
import { nanoid } from "nanoid";
// import "./App.css";
// import data from "../_mocks_/user";
// import ReadOnlyRow from "./readonlyrow";
// import EditableRow from "./editablerow";
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';

import plusFill from '@iconify/icons-eva/plus-fill';
import leftarrow from '@iconify/icons-eva/arrow-circle-left-outline';
// import { Link as RouterLink, Navigate } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Modal, Box, RaisedButton
} from '@mui/material';
// components
// import {  } from 'react-router'

import { Link as RouterLink, Router, useNavigate, useParams, } from 'react-router-dom';
import Carousel, { consts } from "react-elastic-carousel"

import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenuCategory } from '../components/_dashboard/user';


import fire from '../firebaseconfig';



//



function MainCategoryList() {


    const TABLE_HEAD = [

        { id: 'categoryId', label: 'Id', alignRight: false },
        { id: 'categoryName', label: 'Name', alignRight: false },
        { id: 'image', label: 'Card Image', alignRight: false },
        // { id: 'flag', label: 'Flag',alignRight:false },
        // { id: 'monthlyProductId', label: 'Monthly ProductId',alignRight:false },
        // { id: 'YearlyproductId', label: 'Yearly ProductId',alignRight:false },
        { id: 'viewCategory', label: 'Categories', alignRight: false },
        { id: '' }
    ];
    const [page, setPage] = useState(0);

    const [rowsPerPage, setRowsPerPage] = useState(5);


    const [contacts, setContacts] = useState([]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [newpage, setnewpage] = useState()

    const { catpage } = useParams();



    const navigate = useNavigate();


    // const onBackButtonEvent = (e) => {
    //     console.log("eeeee", e)
    //     e.preventDefault();

    //     window.location.replace("http://www.google.com/")

    // }


    useEffect(() => {
        // history.pushState(null, null, document.URL);
        // window.addEventListener('popstate', function () {
        //     history.pushState(null, null, document.URL);
        // });
        // window.addEventListener('popstate', onBackButtonEvent);



        if (catpage) {
            setPage(catpage)
        } else {
            setPage(0)
        }

        console.log("params", catpage)


    }, [catpage])

    useEffect(() => {

        const contacts = fire.database()
            .ref('/packageList/main-category/')
            .once('value')
            .then(async (snapshot) => {
                setContacts(snapshot.val() || [])
            })

        // console.log("page",page)

    }, [contacts])

    const handleChangePage = (event, newPage) => {
        console.log("userlist", newPage, event)
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {

        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

    };

    const handleDeleteClick = async (contactId, item) => {
        await fire.database().ref(`/packageList/main-category/${contactId - 1}`).remove();
        const catsnap = await fire.database().ref('/packageList/sub-category/')
            .orderByChild('categoryid').equalTo(Number(contactId)).once('value');
        const categories = Object.values(catsnap.val() || []);
        const packagesPromise = categories.map(cat => fire.database().ref(`/packageList/packageListindex/`).orderByChild('categoryid').equalTo(Number(cat.id)).once('value'));
        const packages = (await Promise.all(packagesPromise)).map(p => Object.values(p.val() || []));
        packages.map(pack => pack.map(p => fire.database().ref(`/packageList/packageListindex/${p.id - 1}`).remove()));
        categories.map(cat => fire.database().ref(`/packageList/sub-category/${cat.id - 1}`).remove());
        fire.database()
            .ref('/packageList/main-category/')
            .once('value')
            .then(async (snapshot) => {
                const data = Object.values(snapshot.val() || [])
                setContacts(data)
                setPage(0);
            });
    };

    return (
        <Page title="MainCategoryList">
            <Container>

                <Stack direction="row" justifyContent="space-between" mb={3}>
                    <Typography variant="h4" gutterBottom>
                        Main Category
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        // to="/dashboard/add-product/"
                        to={`/dashboard/add-main-category/${page}/`}
                        startIcon={<Icon icon={plusFill} />}
                    // onClick={() => Navigate(`/dashboard/add-product/${page}`)}
                    >
                        New main category
                    </Button>
                </Stack>
                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }} >
                            <Table>
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={contacts.length}
                                />
                                <TableBody >
                                    {
                                        contacts

                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => (

                                                <TableRow
                                                    hover
                                                    id={row.id}
                                                    key={index}
                                                >


                                                    <TableCell align="left" width="50px">{row?.id}</TableCell>
                                                    <TableCell align="left" style={{ textTransform: 'uppercase' }}>{row?.name}</TableCell>
                                                    <TableCell align="left" width="100px">
                                                        <div style={{

                                                            width: 100,
                                                            height: 50,
                                                            // background: 'black',
                                                        }}>
                                                            <img src={row?.image} alt="nice" width={100} height={50} />
                                                        </div>
                                                    </TableCell>
                                                    {/* <TableCell align="left"  style={{ textTransform: 'capitalize' }}>
                                                        {row?.flag}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        iOS: {row?.monthlyProductId} <br />
                                                        Android: {row?.androidmonthlyProductId}
                                                    </TableCell>
                                                    <TableCell align="left" >
                                                        iOS: {row?.productId} <br />
                                                        Android: {row?.androidmoYearlyProductId}
                                                    </TableCell> */}

                                                    <TableCell align="center" width="160px">
                                                        <Button
                                                            component={RouterLink}

                                                            to={`/dashboard/category/0/${row.id}`}
                                                            variant="contained"
                                                            style={{ alignItems: "center", justifyContent: 'center', alignSelf: 'center', textAlign:'center',fontSize: 12 }}
                                                            onClick={() => { localStorage.setItem("mpage", page) }}
                                                        >
                                                            View Categories
                                                        </Button>
                                                    </TableCell>







                                                    <TableCell align="right" width="30px">
                                                        <UserMoreMenuCategory
                                                            id={row.id}
                                                            item={contacts}
                                                            page={page}
                                                            handleDeleteClick={handleDeleteClick}
                                                            route='edit-main-category'
                                                        />
                                                    </TableCell>
                                                </TableRow>

                                            ))
                                    }

                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 30, 35, 40, 45, 50]}
                        component="div"
                        count={contacts.length}
                        rowsPerPage={rowsPerPage}
                        page={Math.min(contacts.length, +page)}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container >
        </Page >
    );

};

export default MainCategoryList;

