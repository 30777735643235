import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import searchFill from '@iconify/icons-eva/arrow-back-outline';
import plusFill from '@iconify/icons-eva/plus-fill';
import { alpha, styled } from '@mui/material/styles';
import { Link as RouterLink, useNavigate, useParams, withRouter } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Container,
  Typography,
  Button,
  Box,
  AppBar,
  Toolbar,
  inputAdornmentClasses
} from '@mui/material';

import {
  ProductSort,
  ProductList,
  ProductCartWidget,
  ProductFilterSidebar,
} from '../components/_dashboard/products';


import fire from '../firebaseconfig';
import Page from '../components/Page';
import DashboardSidebar from '../layouts/dashboard/DashboardSidebar';
import { MHidden } from '../components/@material-extend';




export default function EcommerceShop(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [name, setname] = useState();
  const [desc, setdesc] = useState('');
  const [price, setprice] = useState('');
  const [aimage, setaimage] = useState('');
  const [bimage, setbimage] = useState('');

  const [productId, setproductId] = useState('');
  const [isSubmitting, setsubmitting] = useState(false)
  const [getpackage, setpackage] = useState([]);
  const [editData, seteditData] = useState({});

  const [open, setOpen] = useState(false);
  const [inputList, setinputList] = useState([{ image1: '', image2: '', image3: '', image4: '', sideaimage: '', sidebimage: '', landscapeaimage: '', landscapebimage: '' }]);
  const { id } = useParams();

  const { page } = useParams();
  const [getcatid, setcatid] = useState('')
  const [getmcatid, setmcatid] = useState('')
  const [image, setImage] = useState();
  const [image2, setImage2] = useState();
  const [url, setUrl] = useState();
  const [editimage,setimageedit]=useState();
  const [editimage2,setimageedit2]=useState();
const [geturlonchange,seturl]=useState();
  const LoginSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    desc: Yup.string().required('Description is required'),
   
  });

  const getEditData = async () => {
    console.log("getEditData");
    await fire.database()
      .ref(`/packageList/packageListindex/${id - 1}`)
      .once('value')
      .then((snapshot) => {
        console.log("snapshot")
        console.log(snapshot.val())
        setname(snapshot.val()?.name);
        setdesc(snapshot.val()?.desc);
        setprice(snapshot.val()?.price)
      });

  }

  useEffect(() => {
    console.log("paage", page)
    const catid = localStorage.getItem("catid")
    setcatid(catid)
    const mcatid = localStorage.getItem("mcatid")
    setmcatid(mcatid)

    fire.database()
      .ref('/packageList/packageListindex/')
      .once('value')
      .then(async (snapshot) => {
        setpackage(snapshot.val())
      })
    if (id) {

      console.log("id", id)
      fire.database()
        .ref(`/packageList/packageListindex/${id}`)
        .once('value')
        .then((snapshot) => {
          console.log("snapshot", snapshot.val()?.product[0].sideaimage)
          setFieldValue('name', snapshot.val()?.name)
          setFieldValue('desc', snapshot.val()?.desc);
          
          setimageedit(snapshot.val()?.mainImage)
          setimageedit2(snapshot.val()?.infoimage)
          setinputList(snapshot.val()?.product);
        });
    }
  }, [])


  const handleImageChange = (e) => {
    console.log("eeeee", e, e.target.files[0])
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };
  const handleImageChange2 = (e) => {
    console.log("eeeeehenleImageeeeee2222", e, e.target.files[0])
    if (e.target.files[0]) {
      setImage2(e.target.files[0]);
    }
  };

  const productuploadimage = () => new Promise((resolve, reject) => {

    try {


      const uploadTask =
        fire.storage().ref(`/subcategory/${image.name}`).put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log("xyz", snapshot)

        },
        error => {
          reject(error)

        },

        () => {
          fire.storage()
            .ref('/subcategory/')
            .child(image.name)
            .getDownloadURL()
            .then(async (aurl) => {

              console.log("console", aurl)
              await setUrl(aurl)
              dataaddimagefire(aurl)
              resolve(true)
            })
        }
      )


    } catch (err) {
      reject(err)

    }
  }

  );

  const infoimage = () => new Promise((resolve, reject) => {

    try {

console.log("iamgegegegegegegegege",image2.name)
      const uploadTask =
        fire.storage().ref(`/subcategory/${image2.name}`).put(image2);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log("xyz", snapshot)

        },
        error => {
          reject(error)

        },

        () => {
          fire.storage()
            .ref('/subcategory/')
            .child(image2.name)
            .getDownloadURL()
            .then(async (aurl) => {

              console.log("console", aurl)
              await setUrl(aurl)
              dataaddInfoImage(aurl)
              resolve(true)
            })
        }
      )


    } catch (err) {
      reject(err)

    }
  }

  );

  const dataaddimagefire = (aurl) => new Promise((resolve, reject) => {
    // console.log("url********************",url)
    if (id) {

      fire
        .database()
        .ref(`/packageList/packageListindex/${id}/`)
        .update({
          mainImage: aurl

        }).then(() => {
          console.log("updateed")
          // navigate(`/dashboard/user/${getmcatid}/${getcatid}/${page}`);
          resolve(true)
        }).catch(err => {
          reject(err)
        })
    } else {

      fire
        .database()
        .ref(`/packageList/packageListindex/${getpackage.length}`)
        .update({
          mainImage: aurl

        }).then(() => {

          console.log("updateed")
          // navigate(`/dashboard/user/${getmcatid}/${getcatid}/${page}`);

        })
    }
  })

  const dataaddInfoImage = (aurl) => new Promise((resolve, reject) => {
    // console.log("url********************",url)
    if (id) {

      fire
        .database()
        .ref(`/packageList/packageListindex/${id}/`)
        .update({
          infoimage: aurl

        }).then(() => {
          console.log("updateed")
          // navigate(`/dashboard/user/${getmcatid}/${getcatid}/${page}`);
          resolve(true)
        }).catch(err => {
          reject(err)
        })
    } else {

      fire
        .database()
        .ref(`/packageList/packageListindex/${getpackage.length}`)
        .update({
          infoimage: aurl

        }).then(() => {

          console.log("updateed")
          // navigate(`/dashboard/user/${getmcatid}/${getcatid}/${page}`);
          resolve(true)
        }).catch(err => {
          reject(err)
        })
    }
  })




  const uploadaimage = () => new Promise((resolve, reject) => {
    console.log('upload  a image');
    try {
      inputList.map((images) => {
        console.log(images)
        if (images.image1) {
          console.log("imagessss", images?.image1?.name)
          const uploadTask =
            fire.storage().ref(`/images/${images?.image1?.name}`).put(images.image1);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              console.log("xyz", images.image1.name)

            },
            error => {
              reject(error)

            },

            () => {
              fire.storage()
                .ref('/images/')
                .child(images.image1.name)
                .getDownloadURL()
                .then((aurl, index) => {
                  console.log("imagefjrhgjhtgtygh****************************")
                  console.log("console", aurl)

                  if (images.sideaimage === "") {
                    images.sideaimage = aurl;

                    console.log("valuuueueueueu", images.sideaimage)

                  } else {
                    images.sideaimage = aurl;

                    console.log("valuuueueueueuelse a part", images.sideaimage)
                  }

                  resolve(true)
                })
            }
          )

        }
        return images;
      })
    } catch (err) {
      reject(err)

    }
  }

  );


  const uploadLandscapeaimage = () => new Promise((resolve, reject) => {
    console.log('uploadLandscapeaimage');
    try {
      inputList.map((images) => {
        // console.log(images)
        if (images.image3) {
          console.log("imagessss33333", images?.image3?.name)
          const uploadTask =
            fire.storage().ref(`/images/${images?.image3?.name}`).put(images.image3);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              console.log("xyzupload lanscrap", images.image3.name)

            },
            error => {
              reject(error)

            },

            () => {
              fire.storage()
                .ref('/images/')
                .child(images.image3.name)
                .getDownloadURL()
                .then((landaimage, index) => {
                  console.log("imagefjrhgjhtgtygh****************************Landsapeeppppp")
                  console.log("console", landaimage)

                  if (images.landscapeaimage === "") {
                    images.landscapeaimage = landaimage;

                    console.log("valuuueueueueu", images.landscapeaimage)

                  } else {
                    images.landscapeaimage = landaimage;

                    console.log("valuuueueueueuelse a part", images.landscapeaimage)
                  }

                  resolve(true)
                })
            }
          )

        }
        return images;
      })
    } catch (err) {
      reject(err)

    }
  }

  );


  const uploadLandscapebimage = () => new Promise((resolve, reject) => {
    console.log('uploadLandscapebimage');
    try {
      inputList.map((images) => {
        // console.log(images)
        if (images.image4) {
          console.log("imagessss44444", images?.image4?.name)
          const uploadTask =
            fire.storage().ref(`/images/${images?.image4?.name}`).put(images.image4);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              console.log("xyz", images.image4.name)

            },
            error => {
              reject(error)

            },

            () => {
              fire.storage()
                .ref('/images/')
                .child(images.image4.name)
                .getDownloadURL()
                .then((landbimage, index) => {
                  console.log("imagefjrhgjhtgtygh****************************")
                  console.log("console", landbimage)

                  if (images.landscapebimage === "") {
                    images.landscapebimage = landbimage;

                    console.log("valuuueueueueu", images.landscapebimage)

                  } else {
                    images.landscapebimage = landbimage;

                    console.log("valuuueueueueuelse a part", images.landscapebimage)
                  }

                  resolve(true)
                })
            }
          )

        }
        return images;
      })
    } catch (err) {
      reject(err)

    }
  }

  );

  const uploadbimage = () => new Promise((resolve, reject) => {
    // resolve(true);

    console.log('upload b image');


    try {
      inputList.map((images) => {
        console.log(images)
        if (images.image2) {
          const uploadTask =
            fire.storage().ref(`/images/${images?.image2?.name}`).put(images.image2);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              console.log("xyz")
              console.log("snapshor inside method download", images?.image2)
            },
            error => {
              reject(error)
            },
            () => {
              fire.storage()
                .ref('images')
                .child(images?.image2?.name)
                .getDownloadURL()
                .then((burl, index) => {
                  console.log("imagefjrhgjhtgtygh****************************")
                  console.log("console", burl)
                  if (images.sidebimage === "") {
                    images.sidebimage = burl;
                    console.log("valuuueueueueu", images.sidebimage)
                  } else {
                    images.sidebimage = burl;
                    console.log("valuuueueueueuelsepart", images.sidebimage)
                  }
                  resolve(true)
                })
            }

          )
        }
        return images;
      })
    } catch (err) {
      reject(err)
      console.log(err)
    }
  }

  );



  const dataaddimage = (e) => new Promise((resolve, reject) => {
    if (id) {
      fire
        .database()
        .ref(`/packageList/packageListindex/${id}/`)
        .update({
          categoryid: Number(getcatid),
          name: e.name,
          desc: e.desc,
          
        }).then(() => {
          console.log("updateed")
          // navigate(`/dashboard/user/${getmcatid}/${getcatid}/${page}`);
          resolve(true)
        }).catch(err => {
          reject(err)
        })
    } else {

      fire
        .database()
        .ref(`/packageList/packageListindex/${getpackage.length}`)
        .update({
          id: getpackage.length + 1,
          categoryid: Number(getcatid),
          name: e.name,
          desc: e.desc,
    
        }).then(() => {

          console.log("updateed")
          // navigate(`/dashboard/user/${getmcatid}/${getcatid}/${page}`);
          resolve(true)
        }).catch(err => {
          reject(err)
        })
    }
  })
  const dataaddmultipleimage = () => new Promise((resolve, reject) => {
    if (id) {
      inputList.map((item, index) => {
       console.log("log*************************")
        fire
          .database()
          .ref(`/packageList/packageListindex/${id}/product/${index}`)
          .update({
            id: index + 1,
            sideaimage: item.sideaimage,
            sidebimage: item.sidebimage,
            landscapeaimage: item.landscapeaimage,
            landscapebimage: item.landscapebimage,
          })
          .then(() => {
            console.log("update productsssss")
            // navigate(`/dashboard/user/${getmcatid}/${getcatid}/${page}`);
            resolve(true)
          })
          return {item,index};
        })
    }
    else {
      inputList.map((item, index) => (
       
        fire
          .database()
          .ref(`/packageList/packageListindex/${getpackage.length}/product/${index}`)
          .update({
            id: index + 1,
            sideaimage: item.sideaimage,
            sidebimage: item.sidebimage,
            landscapeaimage: item.landscapeaimage,
            landscapebimage: item.landscapebimage,
          })
          .then(() => {
            console.log("update product")
            // navigate(`/dashboard/user/${getmcatid}/${getcatid}/${page}`);
            resolve(true)
          })
      ))
    }
  })

  const formik = useFormik({
    initialValues: {
      name,
      desc,
      
    },
    validationSchema: LoginSchema,
    onSubmit: async (e) => {
      console.log("here")
      console.log("eee", e, 'aimage', aimage, 'bimage', bimage)

      if (id) {
        try {
          console.log("id", id)
          if (image) {
            await productuploadimage()
            await dataaddimage(e)
            await dataaddmultipleimage()
          } else if (image2) {
            await infoimage()
            await dataaddimage(e)
            await dataaddmultipleimage()
          } else if (image && image2) {
            await productuploadimage()
            await infoimage()
            await dataaddimage(e)
            await dataaddmultipleimage()
          } else {
            await dataaddimage(e)
            await dataaddmultipleimage()
          }
          navigate(`/dashboard/user/${getmcatid}/${getcatid}/${page}`);
        } catch (err) {
          console.log("errrrr", err);
        }
      } else {
        setsubmitting(true)
        try {
          inputList.map(async (images) => {
            // if (!images.image1) {
            //   alert("image is required")
            //   setsubmitting(false)
            // }
            // else if (!images.image2) {
            //   alert("image is required")
            //   setsubmitting(false)
            // }
            if (!images.image3) {
              alert("image is required")
              setsubmitting(false)
            } else if (!images.image4) {
              alert("image is required")
              setsubmitting(false)
            } else if (!image) {
              alert("product Card is required")
              setsubmitting(false)
            } else if(!image2) {
              alert("Info Card is required")
              setsubmitting(false)
            } else {
              setsubmitting(true)
              // await uploadaimage()
              // await uploadbimage()
              // await uploadLandscapeaimage()
              // await uploadLandscapebimage()
              await productuploadimage()
              await infoimage()
              await dataaddimage(e)
              await dataaddmultipleimage()
              setsubmitting(false)
              navigate(`/dashboard/user/${getmcatid}/${getcatid}/${page}`);
            }
          })
        } catch (err) {
          console.error(err);
          alert(err.message);
        }
      }
    }
  });


  const handleinputchange = (e, index) => {
console.log("index**************",index)
    const { name, path } = e.target;
    const list = [...inputList];
    const value = e.target.files[0];

    list[index][name] = value;


    // .split(/[#?]/)[0].split('\\').pop().trim();
    console.log("list********", list, list[index][name],e.target.files[0],e.target.files[0].name,value)
    setinputList(list);

    const uploadTask =
    fire.storage().ref(`/images/${e.target.files[0].name}`).put(e.target.files[0]);
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      console.log("xyz")
      console.log("snapshor inside method download",e.target.files[0])
    },
    error => {
      // reject(error)
    },
    () => {
      fire.storage()
        .ref('images')
        .child(e.target.files[0].name)
        .getDownloadURL()
        .then((burl,index) => {
          console.log("imagefjrhgjhtgtygh****************************",index)
          console.log("console", burl)
         
            // const ai = inputList.findIndex(a=> a.image1 === e.target.files[0]);
            // const ai2 = inputList.findIndex(a=> a.image2 === e.target.files[0]);
            const ai3 = inputList.findIndex(a=> a.image3 === e.target.files[0]);
            const ai4 = inputList.findIndex(a=> a.image4 === e.target.files[0]);

// if (ai !== -1) {
//   console.log("if condition")

//   inputList[ai].sideaimage = burl;
//   // console.log("inputList********",inputList,inputList[ai].sideaimage = burl)

// }
  
// if (ai2 !== -1) {
//   console.log("if condition")
  
//   inputList[ai2].sidebimage = burl;
//   // console.log("inputList********",inputList,inputList[ai].sideaimage = burl)
  
// }
if (ai3 !== -1) {
  console.log("if condition")
 
  inputList[ai3].landscapeaimage = burl;
  // console.log("inputList********",inputList,inputList[ai].sideaimage = burl)
 
 
}
if (ai4 !== -1) {
  console.log("if condition")
 
  inputList[ai4].landscapebimage = burl;
  // console.log("inputList********",inputList,inputList[ai].sideaimage = burl)
 
}
           
         
          
        })
    }

  )
}

  const handleaddclick = () => {
    setinputList([...inputList, { image1: '', image2: '', image3: '', image4: '', sideaimage: '', sidebimage: '', landscapeaimage: '', landscapebimage: '' }]);

  }
  
  const { errors, touched, values, handleSubmit, getFieldProps, setFieldValue } = formik;
  return (

    <Page title={id ? "EditPackageList" : "AddPackageList"}>
      <RootStyle>
        <ToolbarStyle>
          <MHidden width="lgUp">
            <IconButton onClick={() => setOpen(true)} sx={{ mr: 1, color: 'text.primary' }}>
              <Icon icon={menu2Fill} />
            </IconButton>
          </MHidden>
          <IconButton onClick={() => {
            navigate(`/dashboard/user/${getmcatid}/${getcatid}/${page}`);
            // props.history.goBack()
          }}>
            <Icon icon={searchFill} width={25} height={25} />
          </IconButton>





        </ToolbarStyle>
      </RootStyle>
      {/* <DashboardSidebar isOpenSidebar={() => setOpen(true)} onCloseSidebar={() => setOpen(false)} /> */}
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" sx={{ marginTop: 5 }}>
            {id ? 'Edit Product' : 'Add Product'}
          </Typography>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <input type="hidden" name="id" value="" />
              <TextField
                fullWidth
                
                data-shrink="true"
                type="text"
                InputLabelProps={{ shrink: true }}
                
                value={name}
                
                onChange={(e) => setFieldValue('name', e.target.value)}
                label="Enter Product Name"
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />

              <TextField
                fullWidth
                autoComplete="username"
                type="text"
               minRows={4}
                multiline
                InputLabelProps={{ shrink: true }}
                value={desc}
                onChange={(e) => setFieldValue('desc', e.target.value)}
                label="Enter Product Description"
                {...getFieldProps('desc')}
                error={Boolean(touched.desc && errors.desc)}
                helperText={touched.desc && errors.desc}
              />
              <h5>Product Card Image</h5>
              <input type="file" onChange={(e) => { handleImageChange(e) }} />
              {id ? <img alt="nice" src={editimage} width={100} height={100} style={{ marginTop: 10 }} /> : null}

              <h5>Info Card Image</h5>
              <input type="file" onChange={(e) => { handleImageChange2(e) }} />
              {id ? <img alt="nice" src={editimage2} width={100} height={100} style={{ marginTop: 10 }} /> : null}
           
              <Stack direction="row" alignItems="center" sx={{ marginLeft: 20 }}>
                <Typography variant="h5">
                  Upload Card
                </Typography>
                <LoadingButton

                  variant="contained"
                  // loading={isSubmitting}
                  onClick={handleaddclick}
                  style={{ width: '15%', marginLeft: 30 }}
                  startIcon={<Icon icon={plusFill} />}
                >Add Card</LoadingButton>
              </Stack>

              {

                inputList.map((x, i) =>
                  <div>
                    {/* <div>
                      <h3>Upload Image For Card {i + 1}</h3>
                      <br />
                      <h4>Side A Image</h4>
                    </div>
                    <div>
                      <input type="file" name="image1" onChange={e => handleinputchange(e, i)} />

                      {id ? <img alt="nice" src={x.sideaimage} width={100} height={100} style={{ marginTop: 10 }} /> : null}
                    </div>
                    <div>
                      <br />
                      <h4>Side B Image</h4>
                    </div>
                    <div>
                      <input type="file" name="image2" onChange={e => handleinputchange(e, i)} />
                      {id ? <img alt="nice" src={x.sidebimage} width={100} height={100} style={{ marginTop: 10 }} /> : null}
                    </div> */}
                    {/*  */}
                    <div>
                      <br />
                      <h4>LandeScape Side A Image</h4>
                    </div>
                    <div>
                      <input type="file" name="image3" onChange={e => handleinputchange(e, i)} />
                      {id ? <img alt="nice" src={x.landscapeaimage} width='20%' height='20%' style={{ marginTop: 10 }} /> : null}
                    </div>
                    <div>
                      <br />
                      <h4>LandeScape Side B Image</h4>
                    </div>
                    <div>
                      <input type="file" name="image4" onChange={e => handleinputchange(e, i)} />
                      {id ? <img alt="nice" src={x.landscapebimage} width='20%' height='20%' style={{ marginTop: 10 }} /> : null}
                    </div>
                  </div>

                )}

            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              style={{ marginTop: 40 }}
            >
              Save
            </LoadingButton>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));