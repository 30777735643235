// import react, { useState, Fragment, useEffect } from "react";
// import { nanoid } from "nanoid";
// // import "./App.css";
// // import data from "../_mocks_/user";
// // import ReadOnlyRow from "./readonlyrow";
// // import EditableRow from "./editablerow";
// import { filter } from 'lodash';
// import { Icon } from '@iconify/react';
// import { sentenceCase } from 'change-case';
// import searchFill from '@iconify/icons-eva/arrow-back-outline';
// import plusFill from '@iconify/icons-eva/plus-fill';
// import { alpha, styled } from '@mui/material/styles';


// import leftarrow from '@iconify/icons-eva/arrow-circle-left-outline';
// // import { Link as RouterLink, Navigate } from 'react-router-dom';
// // material
// import {
//     Card,
//     Table,
//     Stack,
//     Avatar,
//     Button,
//     Checkbox,
//     TableRow,
//     TableBody,
//     TableCell,
//     Container,
//     Typography,
//     TableContainer,
//     TablePagination,
//     Modal, Box, RaisedButton, IconButton,
//     AppBar,
//     Toolbar,
// } from '@mui/material';
// // components
// import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// // import { browserHistory } from 'react-router'

// import { Link as RouterLink, Router, useNavigate, useParams, withRouter } from 'react-router-dom';
// import Carousel, { consts } from "react-elastic-carousel"

// import Page from '../components/Page';
// import Label from '../components/Label';
// import Scrollbar from '../components/Scrollbar';
// import SearchNotFound from '../components/SearchNotFound';
// import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
// import { MHidden } from '../components/@material-extend';

// import fire from '../firebaseconfig';



// //



// function PackageList() {

//     // const navigate = useNavigate();
//     const TABLE_HEAD = [
//         { id: 'name', label: 'PackageName', alignRight: false },
//         // { id: 'description', label: 'Description', alignRight: false },
//         // { id: 'price', label: 'Price', alignRight: false },
//         { id: 'Product Card Image', label: 'Product Card Image', alignRight: false },
//         { id: 'cardaimage', label: 'Card A Image', alignRight: false },
//         { id: 'cardbimage', label: 'Card B Image', alignRight: false },
//         { id: 'landscapecardaimage', label: 'Landscape Card A Image', alignRight: false },
//         { id: 'landscapecardbimage', label: 'Landscape Card B Image', alignRight: false },
//         { id: '' }
//     ];
//     const [page, setPage] = useState();
//     const [rowsPerPage, setRowsPerPage] = useState(5);
//     const [getimageid, setimage] = useState();
//     const [getimageidb, setimageb] = useState();
//     const [getimagelanda, setimagelanda] = useState();
//     const [getimagelandb, setimagelandb] = useState();
//     const [isopen, setopen] = useState();
//     const [contacts, setContacts] = useState([]);
//     const [open, setOpen] = useState(false);
//     const handleOpen = () => setOpen(true);
//     const handleClose = () => setOpen(false);

//     const [newpage, setnewpage] = useState()
//     const [openb, setOpenb] = useState(false);
//     const handleOpenb = () => setOpenb(true);
//     const handleCloseb = () => setOpenb(false);
//     const { pages } = useParams();
//     const { catid } = useParams();
//     const [openlanda, setOpenlanda] = useState(false);
//     const handleOpenlanda = () => setOpenlanda(true);
//     const handleCloselanda = () => setOpenlanda(false);
//     const [openlandb, setOpenlandb] = useState(false);
//     const handleOpenlandb = () => setOpenlandb(true);
//     const handleCloselandb = () => setOpenlandb(false);
// const [catpage,setcatpage]=useState()
//     const navigate = useNavigate();


//     // const onBackButtonEvent = (e) => {
//     //     console.log("eeeee", e)
//     //     e.preventDefault();

//     //     navigate(-1)

//     // }


//     useEffect(() => {

//         // window.addEventListener('popstate', navigate.goBack());



//         if (pages) {
//             setPage(pages)
//         } else {
//             setPage(0)
//         }
      
//         console.log("params", pages)
       
//     }, [pages])

//     useEffect(() => {
//         console.log("cateid", catid)
//         localStorage.setItem("catid", catid)
//         const getpage =localStorage.getItem("page")
//         setcatpage(getpage)
//         // const id=Object.values(catid)
//         fire.database()
//             .ref().child('/packageList/packageListindex/').
//             orderByChild('categoryid').equalTo(Number(catid))
//             .once('value')
//             .then(async (snapshot) => {
//                 const data = snapshot.val() ? Object.values(snapshot.val()) : []
//                 setContacts(data)

//             })

//         // console.log("page",page)

//     }, [])
//     const captionStyle = {
//         fontSize: '2em',
//         fontWeight: 'bold',
//     }
//     const slideNumberStyle = {
//         fontSize: '20px',
//         fontWeight: 'bold',
//     }

//     const handleChangePage = (event, newPage) => {
//         console.log("userlist", newPage, event)

//         // setnewpage(newPage)

//         setPage(newPage);


//     };

//     const handleChangeRowsPerPage = (event) => {
//         // console.log("event", event.target.value)
//         setRowsPerPage(parseInt(event.target.value, 10));
//         setPage(0);

//     };

//     const handleDeleteClick = async (contactId, item, isOpen) => {
//         await fire.database().ref(`/packageList/packageListindex/${contactId - 1}`).remove();
//         // fire.database()
//         //     .ref('/packageList/packageListindex/')
//         //     .once('value')
//         //     .then(async (snapshot) => {
//         //         const data = Object.values(snapshot.val())
//         //         setContacts(data)
//         //         // setPage(0);
//         //     });
//         fire.database()
//             .ref().child('/packageList/packageListindex/').
//             orderByChild('categoryid').equalTo(Number(catid))
//             .once('value')
//             .then(async (snapshot) => {
//                 const data = snapshot.val() ? Object.values(snapshot.val()) : []
//                 setContacts(data)
//                 setopen(!isOpen)
//                 setPage(0);
//             })
//     };
//     function myArrow({ type, onClick, isEdge }) {

//         const pointer = type === consts.PREV ? '<' : '>'
//         return (

//             <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderWidth: 0 }}>

//                 <Button
//                     variant="contained"
//                     onClick={onClick} disabled={isEdge} style={{ fontSize: 20, borderWidth: 0 }}>
//                     {pointer}
//                 </Button>
//             </div>

//         )
//     }

//     const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - contacts.length) : 0;
//     return (
//         <Page title="PackageList">
//             <RootStyle>
//                 <ToolbarStyle>
//                     <MHidden width="lgUp">
//                         <IconButton onClick={() => setOpen(true)} sx={{ mr: 1, color: 'text.primary' }}>
//                             <Icon icon={menu2Fill} />
//                         </IconButton>
//                     </MHidden>
//                     <IconButton onClick={() => {
//                         navigate(`/dashboard/category/${catpage}`);
//                         // props.history.goBack()
//                     }}>
//                         <Icon icon={searchFill} width={25} height={25} />
//                     </IconButton>





//                 </ToolbarStyle>
//             </RootStyle>
//             <Container>

//                 <Stack direction="row" justifyContent="space-between" mb={3} mt={3}>
//                     <Typography variant="h4" gutterBottom>
//                         Package
//                     </Typography>
//                     <Button
//                         variant="contained"
//                         component={RouterLink}
//                         // to="/dashboard/add-product/"
//                         to={`/dashboard/add-product/${page}`}
//                         startIcon={<Icon icon={plusFill} />}
//                     // onClick={() => Navigate(`/dashboard/add-product/${page}`)}
//                     >
//                         New Package
//                     </Button>
//                 </Stack>
//                 <Card>
//                     <Scrollbar>
//                         {contacts.length >0?
//                             <TableContainer sx={{ minWidth: 800 }} >
//                             <Table>
//                                 <UserListHead
//                                     headLabel={TABLE_HEAD}
//                                     rowCount={contacts.length}
//                                 />
//                                 <TableBody >
//                                     {
//                                         contacts
//                                             // .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
//                                             .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
//                                             .map((row, index) => (
//                                                 // const { id, name, role, status, company, avatarUrl, isVerified } = row;
//                                                 // const isItemSelected = selected.indexOf(name) !== -1;

//                                                 // return (
//                                                 <TableRow
//                                                     hover
//                                                     id={row.id}

//                                                 >

//                                                     <TableCell width='20%' style={{ height: 100, textTransform: 'uppercase' }} >
//                                                         {/* <TableCell align="left">{row.id}</TableCell> */}
//                                                         <Stack direction="row" alignItems="center" spacing={30}>

//                                                             <Typography variant="subtitle2" >
//                                                                 {row?.name}
//                                                             </Typography>
//                                                         </Stack>
//                                                     </TableCell>

//                                                     {/* <TableCell align="left" width='5%' >{row?.price}</TableCell> */}
//                                                     {/* <TableCell align="left" width="10%" >{row?.productId}</TableCell> */}
//                                                     <TableCell align="left" >
//                                                         <div style={{

//                                                             width: 100,
//                                                             height: 50,
//                                                             // background: 'black',
//                                                         }}>
//                                                             <img src={row?.mainImage} alt="nice" width={100} height={50} />
//                                                         </div>
//                                                     </TableCell>
//                                                     <TableCell align="left" width="15%">
//                                                         <Button
//                                                             variant="contained"
//                                                             style={{ alignItems: "center", justifyContent: 'center', alignSelf: 'center', fontSize: 14 }}
//                                                             onClick={() => { setimage(row?.id); handleOpen() }}
//                                                         >
//                                                             Side A Images
//                                                         </Button>
//                                                     </TableCell>
//                                                     {
//                                                         row?.id === getimageid ?

//                                                             <Modal
//                                                                 open={open}
//                                                                 onClose={handleClose}

//                                                             >
//                                                                 <Box
//                                                                     key={row.id}
//                                                                     style={{
//                                                                         height: "100%",
//                                                                         width: "100%",


//                                                                     }}>
//                                                                     <div style={{
//                                                                         position: 'absolute',
//                                                                         top: '50%',
//                                                                         left: '50%',
//                                                                         transform: 'translate(-50%, -50%)',
//                                                                         // pt: 2,
//                                                                         // px: 4,
//                                                                         // pb: 3,
//                                                                         backgroundColor: 'whitesmoke',
//                                                                         width: '50%',

//                                                                         //  width: 650, height: 500
//                                                                         height: '80%'
//                                                                     }}>
//                                                                         <div style={{ marginLeft: 20, marginRight: 20 }}>
//                                                                             <Carousel
//                                                                                 itemsToShow={1}
//                                                                                 pagination

//                                                                                 // showArrows={false}
//                                                                                 renderArrow={(data) => myArrow(data)}
//                                                                                 renderPagination={({ pages, activePage, onClick }) => (

//                                                                                     <div direction="row">
//                                                                                         {pages.map(page => {
//                                                                                             const isActivePage = activePage === page
//                                                                                             return (
//                                                                                                 // <div style={{backgroundColor:'pink',width:100,height:20}} />
//                                                                                                 <button
//                                                                                                     key={page}
//                                                                                                     onClick={() => onClick(page)}
//                                                                                                     style={{ backgroundColor: isActivePage ? '#2699FB' : '#e7e2ef', width: 12, height: 12, marginLeft: 10, marginTop: 15, borderWidth: .5 }}
//                                                                                                     active={isActivePage}
//                                                                                                 />
//                                                                                             )
//                                                                                         })}
//                                                                                     </div>

//                                                                                 )}

//                                                                             >

//                                                                                 {row?.product.map(item => (
//                                                                                     <div style={{
//                                                                                         width: '50%', height: '50%', backgroundColor: 'gray', marginTop: 10
//                                                                                     }}>
//                                                                                         <img src={item?.sideaimage} alt="nice" height={600} width='100%' />
//                                                                                     </div>
//                                                                                 ))}

//                                                                             </Carousel>
//                                                                             <button
//                                                                                 style={{ position: 'absolute', right: 30, width: '12%', height: '5%', backgroundColor: '#2699FB', borderRadius: 10, color: 'white', borderWidth: 0, fontSize: 14, }}
//                                                                                 onClick={handleClose}
//                                                                             >Close</button>
//                                                                         </div>
//                                                                     </div>

//                                                                 </Box>
//                                                             </Modal> : null
//                                                     }
//                                                     <TableCell align="left" width="15%">
//                                                         <Button
//                                                             variant="contained"
//                                                             style={{ alignItems: "center", justifyContent: 'center', alignSelf: 'center', fontSize: 14 }}
//                                                             onClick={() => { setimageb(row?.id); handleOpenb(); }}
//                                                         >
//                                                             Side B Images
//                                                         </Button>
//                                                     </TableCell>
//                                                     {
//                                                         row?.id === getimageidb ?

//                                                             <Modal
//                                                                 open={openb}
//                                                                 onClose={handleCloseb}

//                                                             >
//                                                                 <Box style={{
//                                                                     height: "100%",
//                                                                     width: "100%",


//                                                                 }}>
//                                                                     <div style={{
//                                                                         position: 'absolute',
//                                                                         top: '50%',
//                                                                         left: '50%',
//                                                                         transform: 'translate(-50%, -50%)',
//                                                                         // pt: 2,
//                                                                         // px: 4,
//                                                                         // pb: 3,
//                                                                         backgroundColor: 'whitesmoke',
//                                                                         width: '50%',

//                                                                         //  width: 650, height: 500
//                                                                         height: '80%'
//                                                                     }}>
//                                                                         <div style={{ marginLeft: 20, marginRight: 20 }}>
//                                                                             <Carousel
//                                                                                 itemsToShow={1}
//                                                                                 pagination
//                                                                                 renderArrow={(data) => myArrow(data)}
//                                                                                 renderPagination={({ pages, activePage, onClick }) => (

//                                                                                     <div direction="row">
//                                                                                         {pages.map(page => {
//                                                                                             const isActivePage = activePage === page
//                                                                                             return (
//                                                                                                 // <div style={{backgroundColor:'pink',width:100,height:20}} />
//                                                                                                 <button
//                                                                                                     key={page}
//                                                                                                     onClick={() => onClick(page)}
//                                                                                                     style={{ backgroundColor: isActivePage ? '#2699FB' : '#e7e2ef', width: 12, height: 12, marginLeft: 10, marginTop: 15, borderWidth: .5 }}
//                                                                                                     active={isActivePage}
//                                                                                                 />
//                                                                                             )
//                                                                                         })}
//                                                                                     </div>

//                                                                                 )}
//                                                                             //  pagination
//                                                                             >

//                                                                                 {row?.product.map(item => (
//                                                                                     <div style={{
//                                                                                         width: '50%', height: '50%', backgroundColor: 'gray', marginTop: 10
//                                                                                     }}>
//                                                                                         <img src={item?.sidebimage} alt="nice" height={600} width='100%' />
//                                                                                     </div>
//                                                                                 ))}

//                                                                             </Carousel>
//                                                                             <button
//                                                                                 style={{ position: 'absolute', right: 30, width: '12%', height: '5%', backgroundColor: '#2699FB', borderRadius: 10, color: 'white', borderWidth: 0, fontSize: 14, }}
//                                                                                 onClick={handleCloseb}
//                                                                             >Close</button>
//                                                                         </div>
//                                                                     </div>

//                                                                 </Box>
//                                                             </Modal> : null
//                                                     }

//                                                     <TableCell align="left" width="18%" >
//                                                         <Button
//                                                             variant="contained"
//                                                             style={{ alignItems: "center", justifyContent: 'center', alignSelf: 'center', fontSize: 12 }}
//                                                             onClick={() => { setimagelanda(row?.id); handleOpenlanda(); }}
//                                                         >
//                                                             LandeScape Side A Images
//                                                         </Button>
//                                                     </TableCell>
//                                                     {
//                                                         row?.id === getimagelanda ?

//                                                             <Modal
//                                                                 open={openlanda}
//                                                                 onClose={handleCloselanda}

//                                                             >
//                                                                 <Box style={{
//                                                                     height: "100%",
//                                                                     width: "100%",


//                                                                 }}>
//                                                                     <div style={{
//                                                                         position: 'absolute',
//                                                                         top: '50%',
//                                                                         left: '50%',
//                                                                         transform: 'translate(-50%, -50%)',
//                                                                         // pt: 2,
//                                                                         // px: 4,
//                                                                         // pb: 3,
//                                                                         backgroundColor: 'whitesmoke',
//                                                                         width: '50%',

//                                                                         //  width: 650, height: 500
//                                                                         height: '80%'
//                                                                     }}>
//                                                                         <div style={{ marginLeft: 20, marginRight: 20 }}>
//                                                                             <Carousel
//                                                                                 itemsToShow={1}
//                                                                                 pagination
//                                                                                 renderArrow={(data) => myArrow(data)}
//                                                                                 renderPagination={({ pages, activePage, onClick }) => (

//                                                                                     <div direction="row">
//                                                                                         {pages.map(page => {
//                                                                                             const isActivePage = activePage === page
//                                                                                             return (
//                                                                                                 // <div style={{backgroundColor:'pink',width:100,height:20}} />
//                                                                                                 <button
//                                                                                                     key={page}
//                                                                                                     onClick={() => onClick(page)}
//                                                                                                     style={{ backgroundColor: isActivePage ? '#2699FB' : '#e7e2ef', width: 12, height: 12, marginLeft: 10, marginTop: 15, borderWidth: .5 }}
//                                                                                                     active={isActivePage}
//                                                                                                 />
//                                                                                             )
//                                                                                         })}
//                                                                                     </div>

//                                                                                 )}
//                                                                             //  pagination
//                                                                             >

//                                                                                 {row?.product.map(item => (
//                                                                                     <div style={{
//                                                                                         width: '100%', height: '100%', backgroundColor: 'gray', marginTop: 10
//                                                                                     }}>
//                                                                                         <img src={item?.landscapeaimage} alt="nice" height={600} width='100%' />
//                                                                                     </div>
//                                                                                 ))}

//                                                                             </Carousel>
//                                                                             <button
//                                                                                 style={{ position: 'absolute', right: 30, width: '12%', height: '5%', backgroundColor: '#2699FB', borderRadius: 10, color: 'white', borderWidth: 0, fontSize: 14, }}
//                                                                                 onClick={handleCloselanda}
//                                                                             >Close</button>
//                                                                         </div>
//                                                                     </div>

//                                                                 </Box>
//                                                             </Modal> : null
//                                                     }
//                                                     <TableCell align="left" width="18%">
//                                                         <Button
//                                                             variant="contained"
//                                                             style={{ alignItems: "center", justifyContent: 'center', alignSelf: 'center', fontSize: 12 }}
//                                                             onClick={() => { setimagelandb(row?.id); handleOpenlandb(); }}
//                                                         >
//                                                             LandeScape Side B Images
//                                                         </Button>
//                                                     </TableCell>
//                                                     {
//                                                         row?.id === getimagelandb ?

//                                                             <Modal
//                                                                 open={openlandb}
//                                                                 onClose={handleCloselandb}

//                                                             >
//                                                                 <Box style={{
//                                                                     height: "100%",
//                                                                     width: "100%",


//                                                                 }}>
//                                                                     <div style={{
//                                                                         position: 'absolute',
//                                                                         top: '50%',
//                                                                         left: '50%',
//                                                                         transform: 'translate(-50%, -50%)',

//                                                                         backgroundColor: 'whitesmoke',
//                                                                         width: '50%',

//                                                                         //  width: 650, height: 500
//                                                                         height: '80%'
//                                                                     }}>
//                                                                         <div style={{ marginLeft: 20, marginRight: 20 }}>
//                                                                             <Carousel
//                                                                                 itemsToShow={1}
//                                                                                 pagination
//                                                                                 renderArrow={(data) => myArrow(data)}
//                                                                                 renderPagination={({ pages, activePage, onClick }) => (

//                                                                                     <div direction="row">
//                                                                                         {pages.map(page => {
//                                                                                             const isActivePage = activePage === page
//                                                                                             return (
//                                                                                                 // <div style={{backgroundColor:'pink',width:100,height:20}} />
//                                                                                                 <button
//                                                                                                     key={page}
//                                                                                                     onClick={() => onClick(page)}
//                                                                                                     style={{ backgroundColor: isActivePage ? '#2699FB' : '#e7e2ef', width: 12, height: 12, marginLeft: 10, marginTop: 15, borderWidth: .5 }}
//                                                                                                     active={isActivePage}
//                                                                                                 />
//                                                                                             )
//                                                                                         })}
//                                                                                     </div>

//                                                                                 )}
//                                                                             //  pagination
//                                                                             >

//                                                                                 {row?.product.map(item => (
//                                                                                     <div style={{
//                                                                                         width: '100%', height: '100%', backgroundColor: 'gray', marginTop: 10
//                                                                                     }}>
//                                                                                         <img src={item?.landscapebimage} alt="nice" height={600} width='100%' />
//                                                                                     </div>
//                                                                                 ))}

//                                                                             </Carousel>
//                                                                             <button
//                                                                                 style={{ position: 'absolute', right: 30, width: '12%', height: '5%', backgroundColor: '#2699FB', borderRadius: 10, color: 'white', borderWidth: 0, fontSize: 14, }}
//                                                                                 onClick={handleCloselandb}
//                                                                             >Close</button>
//                                                                         </div>
//                                                                     </div>

//                                                                 </Box>
//                                                             </Modal> : null
//                                                     }
//                                                     < TableCell align="right" width="2%">
//                                                         <UserMoreMenu
//                                                             id={row.id}
//                                                             item={contacts}
//                                                             page={page}

//                                                             handleDeleteClick={handleDeleteClick}

//                                                         />
//                                                     </TableCell>
//                                                 </TableRow>

//                                             ))
//                                     }
//                                     {/* {emptyRows > 0 && (
//                                         <TableRow style={{ height: 53 * emptyRows }}>
//                                             <TableCell colSpan={6} />
//                                         </TableRow>
//                                     )} */}
//                                 </TableBody>

//                             </Table>

//                         </TableContainer>: 
//                         <TableContainer sx={{ minWidth: 800 }} >
//                             <Table>
//                                 <UserListHead
//                                     headLabel={TABLE_HEAD}
//                                     rowCount={contacts.length}
//                                 />
//                                 <TableBody >
//                                     {emptyRows > 0 && (
//                                         <TableRow style={{ height: 53 * emptyRows }}>
//                                             <TableCell colSpan={6} />
//                                         </TableRow>
//                                     )}
//                                 </TableBody>

//                             </Table>

//                         </TableContainer>}
//                     </Scrollbar>

//                     <TablePagination
//                         rowsPerPageOptions={[5, 10, 25, 30, 35, 40, 45, 50]}
//                         component="div"
//                         count={contacts.length}
//                         rowsPerPage={rowsPerPage}
//                         page={Math.min(contacts.length, page)}
//                         onPageChange={handleChangePage}
//                         onRowsPerPageChange={handleChangeRowsPerPage}
//                     />
//                 </Card>
//             </Container >
//         </Page >
//     );

// };

// export default PackageList;

// const DRAWER_WIDTH = 280;
// const APPBAR_MOBILE = 64;
// const APPBAR_DESKTOP = 92;

// const RootStyle = styled(AppBar)(({ theme }) => ({
//     boxShadow: 'none',
//     backdropFilter: 'blur(6px)',
//     WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
//     backgroundColor: alpha(theme.palette.background.default, 0.72),
//     [theme.breakpoints.up('lg')]: {
//         width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
//     }
// }));

// const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
//     minHeight: APPBAR_MOBILE,
//     [theme.breakpoints.up('lg')]: {
//         minHeight: APPBAR_DESKTOP,
//         padding: theme.spacing(0, 5)
//     }
// }));


import react, { useState, Fragment, useEffect } from "react";
import { nanoid } from "nanoid";
// import "./App.css";
// import data from "../_mocks_/user";
// import ReadOnlyRow from "./readonlyrow";
// import EditableRow from "./editablerow";
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import searchFill from '@iconify/icons-eva/arrow-back-outline';
import plusFill from '@iconify/icons-eva/plus-fill';
import { alpha, styled } from '@mui/material/styles';


import leftarrow from '@iconify/icons-eva/arrow-circle-left-outline';
// import { Link as RouterLink, Navigate } from 'react-router-dom';
// material
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Modal, Box, RaisedButton, IconButton,
    AppBar,
    Toolbar,
} from '@mui/material';
// components
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// import { browserHistory } from 'react-router'

import { Link as RouterLink, Router, useNavigate, useParams, withRouter } from 'react-router-dom';
import Carousel, { consts } from "react-elastic-carousel"

import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import { MHidden } from '../components/@material-extend';

import fire from '../firebaseconfig';



//



function PackageList({navigation}) {

    // const navigate = useNavigate();
    const TABLE_HEAD = [
        { id: 'name', label: 'PackageName', alignRight: false },
        // { id: 'description', label: 'Description', alignRight: false },
        // { id: 'price', label: 'Price', alignRight: false },
        { id: 'Product Card Image', label: 'Product Card Image', alignRight: false },
        {id:'Info Card Image',label:'Info Card Image'},
        // { id: 'cardaimage', label: 'Card A Image', alignRight: false },
        // { id: 'cardbimage', label: 'Card B Image', alignRight: false },
        { id: 'landscapecardaimage', label: 'Card A Image', alignRight: false },
        { id: 'landscapecardbimage', label: 'Card B Image', alignRight: false },
        { id: '' }
    ];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [getimageid, setimage] = useState();
    const [getimageidb, setimageb] = useState();
    const [getimagelanda, setimagelanda] = useState();
    const [getimagelandb, setimagelandb] = useState();
    const [isopen, setopen] = useState();
    const [contacts, setContacts] = useState([]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [newpage, setnewpage] = useState()
    const [openb, setOpenb] = useState(false);
    const handleOpenb = () => setOpenb(true);
    const handleCloseb = () => setOpenb(false);
    const { pages, catid, mcatid } = useParams();
    const [openlanda, setOpenlanda] = useState(false);
    const handleOpenlanda = () => setOpenlanda(true);
    const handleCloselanda = () => setOpenlanda(false);
    const [openlandb, setOpenlandb] = useState(false);
    const handleOpenlandb = () => setOpenlandb(true);
    const handleCloselandb = () => setOpenlandb(false);
const [catpage,setcatpage]=useState()
    const navigate = useNavigate();


    // const onBackButtonEvent = (e) => {
    //     console.log("eeeee", e)
    //     e.preventDefault();

    //     navigate(-1)

    // }


    useEffect(() => {

        // window.addEventListener('popstate', navigate.goBack());



        if (pages) {
            setPage(pages)
        } else {
            setPage(0)
        }
      
        console.log("params", pages)
       
    }, [pages])

    useEffect(() => {
        console.log("cateid", catid)
        console.log("mcateid", mcatid)
        localStorage.setItem("catid", catid)
        localStorage.setItem("mcatid", mcatid)
        const getpage =localStorage.getItem("page")
        setcatpage(getpage)
        // const id=Object.values(catid)
        fire.database()
            .ref().child('/packageList/packageListindex/').
            orderByChild('categoryid').equalTo(Number(catid))
            .once('value')
            .then(async (snapshot) => {
                const data = snapshot.val() ? Object.values(snapshot.val()) : []
                setContacts(data)

            })

            
        // console.log("page",page)
      
    }, [])
    const captionStyle = {
        fontSize: '2em',
        fontWeight: 'bold',
    }
    const slideNumberStyle = {
        fontSize: '20px',
        fontWeight: 'bold',
    }

    const handleChangePage = (event, newPage) => {
        console.log("userlist", newPage, event)

        // setnewpage(newPage)

        setPage(newPage);


    };

    const handleChangeRowsPerPage = (event) => {
        // console.log("event", event.target.value)
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);

    };

    const handleDeleteClick = async (contactId, item, isOpen) => {
        await fire.database().ref(`/packageList/packageListindex/${contactId - 1}`).remove();
        // fire.database()
        //     .ref('/packageList/packageListindex/')
        //     .once('value')
        //     .then(async (snapshot) => {
        //         const data = Object.values(snapshot.val())
        //         setContacts(data)
        //         // setPage(0);
        //     });
        fire.database()
            .ref().child('/packageList/packageListindex/')
            .orderByChild('categoryid').equalTo(Number(catid))
            .once('value')
            .then(async (snapshot) => {
                const data = snapshot.val() ? Object.values(snapshot.val()) : []
                setContacts(data)
                setopen(!isOpen)
                setPage(0);
            })
    };
    function myArrow({ type, onClick, isEdge }) {

        const pointer = type === consts.PREV ? '<' : '>'
        return (

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderWidth: 0 }}>

                <Button
                    variant="contained"
                    onClick={onClick} disabled={isEdge} style={{ fontSize: 20, borderWidth: 0 }}>
                    {pointer}
                </Button>
            </div>

        )
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - contacts.length) : 0;
    return (
        <Page title="PackageList">
            <RootStyle>
                <ToolbarStyle>
                    <MHidden width="lgUp">
                        <IconButton onClick={() => setOpen(true)} sx={{ mr: 1, color: 'text.primary' }}>
                            <Icon icon={menu2Fill} />
                        </IconButton>
                    </MHidden>
                    <IconButton onClick={() => {
                        navigate(`/dashboard/category/${catpage}/${mcatid}`);
                        // props.history.goBack()
                    }}>
                        <Icon icon={searchFill} width={25} height={25} />
                    </IconButton>





                </ToolbarStyle>
            </RootStyle>
            <Container>

                <Stack direction="row" justifyContent="space-between" mb={3} mt={3}>
                    <Typography variant="h4" gutterBottom>
                        Package
                    </Typography>
                    <Button
                        variant="contained"
                        component={RouterLink}
                        // to="/dashboard/add-product/"
                        to={`/dashboard/add-product/${page}`}
                        startIcon={<Icon icon={plusFill} />}
                    // onClick={() => Navigate(`/dashboard/add-product/${page}`)}
                    >
                        New Package
                    </Button>
                </Stack>
                <Card>
                    <Scrollbar>
                        {contacts.length >0?
                            <TableContainer sx={{ minWidth: 800 }} >
                            <Table>
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={contacts.length}
                                />
                                <TableBody >
                                    {
                                        contacts
                                            // .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row, index) => (
                                                // const { id, name, role, status, company, avatarUrl, isVerified } = row;
                                                // const isItemSelected = selected.indexOf(name) !== -1;

                                                // return (
                                                <TableRow
                                                    hover
                                                    id={row.id}
                                                    key={index}
                                                >

                                                    <TableCell width='20%' style={{ height: 100, textTransform: 'uppercase' }} >
                                                        {/* <TableCell align="left">{row.id}</TableCell> */}
                                                        <Stack direction="row" alignItems="center" spacing={30}>

                                                            <Typography variant="subtitle2" >
                                                                {row?.name}
                                                            </Typography>
                                                        </Stack>
                                                    </TableCell>

                                                    {/* <TableCell align="left" width='5%' >{row?.price}</TableCell> */}
                                                    {/* <TableCell align="left" width="10%" >{row?.productId}</TableCell> */}
                                                    <TableCell align="left" >
                                                        <div style={{

                                                            width: 100,
                                                            height: 50,
                                                            // background: 'black',
                                                        }}>
                                                            <img src={row?.mainImage} alt="nice" width={100} height={50} />
                                                        </div>
                                                    </TableCell>
                                                    {/* <TableCell align="left" width="15%">
                                                        <Button
                                                            variant="contained"
                                                            style={{ alignItems: "center", justifyContent: 'center', alignSelf: 'center', fontSize: 14 }}
                                                            onClick={() => { setimage(row?.id); handleOpen() }}
                                                        >
                                                            Side A Images
                                                        </Button>
                                                    </TableCell>
                                                    {
                                                        row?.id === getimageid ?

                                                            <Modal
                                                                open={open}
                                                                onClose={handleClose}

                                                            >
                                                                <Box
                                                                    key={row.id}
                                                                    style={{
                                                                        height: "100%",
                                                                        width: "100%",


                                                                    }}>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        top: '50%',
                                                                        left: '50%',
                                                                        transform: 'translate(-50%, -50%)',
                                                                        // pt: 2,
                                                                        // px: 4,
                                                                        // pb: 3,
                                                                        backgroundColor: 'whitesmoke',
                                                                        width: '50%',

                                                                        //  width: 650, height: 500
                                                                        height: '80%'
                                                                    }}>
                                                                        <div style={{ marginLeft: 20, marginRight: 20 }}>
                                                                            <Carousel
                                                                                itemsToShow={1}
                                                                                pagination

                                                                                // showArrows={false}
                                                                                renderArrow={(data) => myArrow(data)}
                                                                                renderPagination={({ pages, activePage, onClick }) => (

                                                                                    <div direction="row">
                                                                                        {pages.map(page => {
                                                                                            const isActivePage = activePage === page
                                                                                            return (
                                                                                                // <div style={{backgroundColor:'pink',width:100,height:20}} />
                                                                                                <button
                                                                                                    key={page}
                                                                                                    onClick={() => onClick(page)}
                                                                                                    style={{ backgroundColor: isActivePage ? '#2699FB' : '#e7e2ef', width: 12, height: 12, marginLeft: 10, marginTop: 15, borderWidth: .5 }}
                                                                                                    active={isActivePage}
                                                                                                />
                                                                                            )
                                                                                        })}
                                                                                    </div>

                                                                                )}

                                                                            >

                                                                                {row?.product.map(item => (
                                                                                    <div style={{
                                                                                        width: '50%', height: '50%', backgroundColor: 'gray', marginTop: 10
                                                                                    }}>
                                                                                        <img src={item?.sideaimage} alt="nice" height={600} width='100%' />
                                                                                    </div>
                                                                                ))}

                                                                            </Carousel>
                                                                            <button
                                                                                style={{ position: 'absolute', right: 30, width: '12%', height: '5%', backgroundColor: '#2699FB', borderRadius: 10, color: 'white', borderWidth: 0, fontSize: 14, }}
                                                                                onClick={handleClose}
                                                                            >Close</button>
                                                                        </div>
                                                                    </div>

                                                                </Box>
                                                            </Modal> : null
                                                    }
                                                    <TableCell align="left" width="15%">
                                                        <Button
                                                            variant="contained"
                                                            style={{ alignItems: "center", justifyContent: 'center', alignSelf: 'center', fontSize: 14 }}
                                                            onClick={() => { setimageb(row?.id); handleOpenb(); }}
                                                        >
                                                            Side B Images
                                                        </Button>
                                                    </TableCell>
                                                    {
                                                        row?.id === getimageidb ?

                                                            <Modal
                                                                open={openb}
                                                                onClose={handleCloseb}

                                                            >
                                                                <Box style={{
                                                                    height: "100%",
                                                                    width: "100%",


                                                                }}>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        top: '50%',
                                                                        left: '50%',
                                                                        transform: 'translate(-50%, -50%)',
                                                                        // pt: 2,
                                                                        // px: 4,
                                                                        // pb: 3,
                                                                        backgroundColor: 'whitesmoke',
                                                                        width: '50%',

                                                                        //  width: 650, height: 500
                                                                        height: '80%'
                                                                    }}>
                                                                        <div style={{ marginLeft: 20, marginRight: 20 }}>
                                                                            <Carousel
                                                                                itemsToShow={1}
                                                                                pagination
                                                                                renderArrow={(data) => myArrow(data)}
                                                                                renderPagination={({ pages, activePage, onClick }) => (

                                                                                    <div direction="row">
                                                                                        {pages.map(page => {
                                                                                            const isActivePage = activePage === page
                                                                                            return (
                                                                                                // <div style={{backgroundColor:'pink',width:100,height:20}} />
                                                                                                <button
                                                                                                    key={page}
                                                                                                    onClick={() => onClick(page)}
                                                                                                    style={{ backgroundColor: isActivePage ? '#2699FB' : '#e7e2ef', width: 12, height: 12, marginLeft: 10, marginTop: 15, borderWidth: .5 }}
                                                                                                    active={isActivePage}
                                                                                                />
                                                                                            )
                                                                                        })}
                                                                                    </div>

                                                                                )}
                                                                            //  pagination
                                                                            >

                                                                                {row?.product.map(item => (
                                                                                    <div style={{
                                                                                        width: '50%', height: '50%', backgroundColor: 'gray', marginTop: 10
                                                                                    }}>
                                                                                        <img src={item?.sidebimage} alt="nice" height={600} width='100%' />
                                                                                    </div>
                                                                                ))}

                                                                            </Carousel>
                                                                            <button
                                                                                style={{ position: 'absolute', right: 30, width: '12%', height: '5%', backgroundColor: '#2699FB', borderRadius: 10, color: 'white', borderWidth: 0, fontSize: 14, }}
                                                                                onClick={handleCloseb}
                                                                            >Close</button>
                                                                        </div>
                                                                    </div>

                                                                </Box>
                                                            </Modal> : null
                                                    } */}
                                                   
            
                                                   <TableCell align="left" >
                                                        <div style={{

                                                            width: 100,
                                                            height: 50,
                                                            // background: 'black',
                                                        }}>
                                                            <img src={row?.infoimage} alt="nice" width={100} height={50} />
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left" >
                                                        <Button
                                                            variant="contained"
                                                            style={{ alignItems: "center", justifyContent: 'center', alignSelf: 'center', fontSize: 12 }}
                                                            onClick={() => { setimagelanda(row?.id); handleOpenlanda(); }}
                                                        >
                                                            Card Side A Images
                                                        </Button>
                                                    </TableCell>
                                                    {
                                                        row?.id === getimagelanda ?

                                                            <Modal
                                                                open={openlanda}
                                                                onClose={handleCloselanda}

                                                            >
                                                                <Box style={{
                                                                    height: "100%",
                                                                    width: "100%",


                                                                }}>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        top: '50%',
                                                                        left: '50%',
                                                                        transform: 'translate(-50%, -50%)',
                                                                        // pt: 2,
                                                                        // px: 4,
                                                                        // pb: 3,
                                                                        backgroundColor: 'whitesmoke',
                                                                        width: '50%',

                                                                        //  width: 650, height: 500
                                                                        // height: '80%'
                                                                    }}>
                                                                        <button
                                                                        style={{
                                                                            position: 'absolute',
                                                                            /* float: right; */
                                                                            height: '1%',
                                                                            backgroundColor: 'transparent',
                                                                            
                                                                            color: 'white',
                                                                            // borderwidth: '0',
                                                                            fontSize: 25,
                                                                            right:10,
                                                                            // marginLeft: 746,
                                                                            paddingBottom: 2,
                                                                            top: -38,
                                                                            border:'none'
                                                                        }}
                                                                                // style={{ position: 'absolute', right: 30, width: '12%', height: '5%', backgroundColor: '#2699FB', borderRadius: 10, color: 'white', borderWidth: 0, fontSize: 14, }}
                                                                                onClick={handleCloselanda}
                                                                            >X</button>
                                                                        <div style={{ marginLeft: 20, marginRight: 20 }}>
                                                                            <Carousel
                                                                                itemsToShow={1}
                                                                                pagination
                                                                                renderArrow={(data) => myArrow(data)}
                                                                                renderPagination={({ pages, activePage, onClick }) => (

                                                                                    <div direction="row">
                                                                                        {pages.map(page => {
                                                                                            const isActivePage = activePage === page
                                                                                            return (
                                                                                                // <div style={{backgroundColor:'pink',width:100,height:20}} />
                                                                                                <button
                                                                                                    key={page}
                                                                                                    onClick={() => onClick(page)}
                                                                                                    style={{ backgroundColor: isActivePage ? '#2699FB' : '#e7e2ef', width: 6, height: 6, marginLeft: 10, marginTop: 15, borderWidth: .5 }}
                                                                                                />
                                                                                            )
                                                                                        })}
                                                                                    </div>

                                                                                )}
                                                                            //  pagination
                                                                            >

                                                                                {row?.product.map((item, index) => (
                                                                                    <div key={index} style={{
                                                                                        width: '100%', height: '100%', backgroundColor: 'gray', marginTop: 10
                                                                                    }}>
                                                                                        <img src={item?.landscapeaimage} alt="nice"  width='100%' />
                                                                                    </div>
                                                                                ))}

                                                                            </Carousel>
                                                                            
                                                                        </div>
                                                                    </div>

                                                                </Box>
                                                            </Modal> : null
                                                    }
                                                    <TableCell align="left">
                                                        <Button
                                                            variant="contained"
                                                            style={{ alignItems: "center", justifyContent: 'center', alignSelf: 'center', fontSize: 12 }}
                                                            onClick={() => { setimagelandb(row?.id); handleOpenlandb(); }}
                                                        >
                                                            Card Side B Images
                                                        </Button>
                                                    </TableCell>
                                                    {
                                                        row?.id === getimagelandb ?

                                                            <Modal
                                                                open={openlandb}
                                                                onClose={handleCloselandb}

                                                            >
                                                                <Box style={{
                                                                    height: "100%",
                                                                    width: "100%",


                                                                }}>
                                                                    <div style={{
                                                                        position: 'absolute',
                                                                        top: '50%',
                                                                        left: '50%',
                                                                        transform: 'translate(-50%, -50%)',

                                                                        backgroundColor: 'whitesmoke',
                                                                        width: '50%',

                                                                        //  width: 650, height: 500
                                                                        height: 'auto'
                                                                    }}>
                                                                         <button
                                                                        style={{
                                                                            position: 'absolute',
                                                                            /* float: right; */
                                                                            height: '1%',
                                                                            backgroundColor: 'transparent',
                                                                            
                                                                            color: 'white',
                                                                            // borderwidth: '0',
                                                                            fontSize: 25,
                                                                            right:10,
                                                                            // marginLeft: 746,
                                                                            paddingBottom: 2,
                                                                            top: -38,
                                                                            border:'none'
                                                                        }}
                                                                                // style={{ position: 'absolute', right: 30, width: '12%', height: '5%', backgroundColor: '#2699FB', borderRadius: 10, color: 'white', borderWidth: 0, fontSize: 14, }}
                                                                                onClick={handleCloselandb}
                                                                            >X</button>
                                                                        <div style={{ marginLeft: 20, marginRight: 20 }}>
                                                                            <Carousel
                                                                                itemsToShow={1}
                                                                                pagination
                                                                                renderArrow={(data) => myArrow(data)}
                                                                                renderPagination={({ pages, activePage, onClick }) => (

                                                                                    <div direction="row">
                                                                                        {pages.map(page => {
                                                                                            const isActivePage = activePage === page
                                                                                            return (
                                                                                                // <div style={{backgroundColor:'pink',width:100,height:20}} />
                                                                                                <button
                                                                                                    key={page}
                                                                                                    onClick={() => onClick(page)}
                                                                                                    style={{ backgroundColor: isActivePage ? '#2699FB' : '#e7e2ef', width: 12, height: 12, marginLeft: 10, marginTop: 15, borderWidth: .5 }}
                                                                                                />
                                                                                            )
                                                                                        })}
                                                                                    </div>

                                                                                )}
                                                                            //  pagination
                                                                            >

                                                                                {row?.product.map((item, index) => (
                                                                                    <div key={index} style={{
                                                                                        width: '100%', height: '100%', backgroundColor: 'gray', marginTop: 10
                                                                                    }}>
                                                                                        <img src={item?.landscapebimage} alt="nice"  width='100%' />
                                                                                    </div>
                                                                                ))}

                                                                            </Carousel>
                                                                            {/* <button
                                                                                style={{ position: 'absolute', right: 30, width: '12%', height: '5%', backgroundColor: '#2699FB', borderRadius: 10, color: 'white', borderWidth: 0, fontSize: 14, }}
                                                                                onClick={handleCloselandb}
                                                                            >Close</button> */}
                                                                        </div>
                                                                    </div>

                                                                </Box>
                                                            </Modal> : null
                                                    }
                                                    < TableCell align="right">
                                                        <UserMoreMenu
                                                            id={row.id}
                                                            item={contacts}
                                                            page={page}

                                                            handleDeleteClick={handleDeleteClick}

                                                        />
                                                    </TableCell>
                                                </TableRow>

                                            ))
                                    }
                                    {/* {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )} */}
                                </TableBody>

                            </Table>

                        </TableContainer>: 
                        <TableContainer sx={{ minWidth: 800 }} >
                            <Table>
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={contacts.length}
                                />
                                <TableBody >
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                            </Table>

                        </TableContainer>}
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 30, 35, 40, 45, 50]}
                        component="div"
                        count={contacts.length}
                        rowsPerPage={rowsPerPage}
                        page={Math.min(contacts.length, +page)}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container >
        </Page >
    );

};

export default PackageList;

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}));


