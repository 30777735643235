// import React, { useState } from "react";
// import {Container} from '@mui/material';


// export default function Blog(){
//   const [inputList, setinputList]= useState([{firstName:'', lastName:''}]);

//   const handleinputchange=(e, index)=>{
//     const {name, value}= e.target;
//     const list= [...inputList];
//     list[index][name]= value.split(/[#?]/)[0].split('\\').pop().trim();
//     console.log("list********",list, list[index][name],value.split(/[#?]/)[0].split('\\').pop().trim())
//     setinputList(list);

//   }
 
//   const handleremove= index=>{
//     const list=[...inputList];
//     list.splice(index,1);
//     setinputList(list);
//   }

//   const handleaddclick=()=>{ 
//     setinputList([...inputList, { firstName:'', lastName:''}]);
//   }
//   return (
//     <Container className="content" style={{marginTop:'20%'}}>
//      <div className="row">
//        <div >
//          <h5 >Dynamically add/remove inputs fields reactjs </h5>
           
//             { 
//             inputList.map((x,i)=>
             
//               <div >
//                  <div>
//                  {/* <label>First Name</label> */}
//                   <input type="file"  name="firstName"  placeholder="Enter First Name" onChange={ e=>handleinputchange(e,i)} />
//                </div>
//                <div>
//                {/* <label>Last Name</label> */}
//                   <input type="file"  name="lastName"  placeholder="Enter Last Name" onChange={ e=>handleinputchange(e,i) }/>
//                </div>
//                <div >
//                {
//                   inputList.length!==1 &&
//                   <button   onClick={()=> handleremove(i)}>Remove</button>
//                }
//                { inputList.length-1===i &&
//                <button  onClick={ handleaddclick}>Add More</button>
//                }
//                </div>
//             </div>
              
//               )} 

               
//        </div>
//      </div>
//     </Container>
//   );
// }
// import React, { useState } from "react";
// import fire from "../firebaseconfig";

// export default function ReactFirebaseFileUpload ()  {
//   const [images, setImages] = useState([]);
//   const [urls, setUrls] = useState([]);
//   const [progress, setProgress] = useState(0);

//   const handleChange =async (e) => {
//      for (let i = 0; i < e.target.files.length; i+1) {
//       // images.map((index)=>{
//         const newImage = e.target.files[0];
//         console.log("newImag",newImage)
//         newImage.id = Math.random();
//         setImages((prevState) => [...prevState, newImage]);
//         // return index;
//       // })
     
//     }
//   };

//   const handleUpload = () => {
//     const promises = [];
//     images.map((image) => {
//       const uploadTask = fire.storage().ref(`images/${image.name}`).put(image);
//       promises.push(uploadTask);
//       uploadTask.on(
//         "state_changed",
//         (snapshot) => {
//           const progress = Math.round(
//             (snapshot.bytesTransferred / snapshot.totalBytes) * 100
//           );
//           setProgress(progress);
//         },
//         (error) => {
//           console.log(error);
//         },
//         async () => {
//           await fire.storage()
//             .ref("images")
//             .child(image.name)
//             .getDownloadURL()
//             .then((urls) => {
//               setUrls((prevState) => [...prevState, urls]);
//             });
//         }
//       );
//       return image;
//     });

//     Promise.all(promises)
//       .then(() => alert("All images uploaded"))
//       .catch((err) => console.log(err));
//   };

//   console.log("images: ", images);
//   console.log("urls", urls);

//   return (
//     <div>
//       <progress value={progress} max="100"  style={{marginTop:'20%'}}/>
//       <br />
//       <br />
//       <input type="file" multiple onChange={handleChange} style={{marginTop:'30%'}}/>
//       <button onClick={handleUpload}  style={{marginTop:'30%'}}>Upload</button>
//       <br />
//       {urls.map((url, i) => (
//         <div key={i}>
//           <a href={url} target="">
//             {url}
//           </a>
//         </div>
//       ))}
//       <br />
//       {urls.map((url, i) => (
//         <img
//           key={i}
//           style={{ width: "500px" }}
//           src={url || "http://via.placeholder.com/300"}
//           alt="nice"
//         />
//       ))}
//     </div>
//   );
// };

import React, { useState } from "react";

const Example =()=> {

  // Initialise state
  const [ radio, setRadio ] = useState(0);

  // When the button is clicked log the current state
  function handleClick(e) {
    console.log(radio);
  }

  // When an element is clicked
  function handleChange(e) {

    // Grab the nodeName and value from
    // the clicked element
    const { nodeName, value } = e.target;

    // Because we're using event delegation (attaching
    // an event listener to a parent element and capturing
    // events from child elements as they "bubble up" the DOM)
    // we need to check to see if the clicked element is an input
    if (nodeName === 'INPUT') {

      // Set the state with the input value
      setRadio(value);
    }
  }

  return (
    <div onChange={handleChange}>
      <div className="form-check">
        <input
          type="radio"
          className="form-check-input"
          value={3}
          name="priority"
        />
        {/* <label className="form-check-label">High Priority</label> */}
      </div>
      <div className="form-check">
        <input
          type="radio"
          value={2}
          className="form-check-input"
          name="priority"
        />
        {/* <label className="form-check-label">Medium Priority</label> */}
      </div>
      <div className="form-check">
        <input
          type="radio"
          value={1}
          className="form-check-input"
          name="priority"
        />
        {/* <label className="form-check-label">Low Priority</label> */}
      </div>
      <button onClick={handleClick}>Show radio state</button>
    </div>
  );
};

export default Example;